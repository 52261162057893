import { useScrollLock } from 'packages/framework/hooks/useScrollLock';
import { useStyle } from 'packages/framework/util/classify';
import Footer from 'components/Footer';
import Header from 'components/Header';
import defaultClasses from './main.module.scss';
import { lazy, Suspense, useEffect, useState } from 'react';
import { sendPageView } from 'packages/util/gtm';


const Main = props => {
  const { children, isMasked } = props;
  const classes = useStyle(defaultClasses, props.classes);

  const rootClass = isMasked ? classes.root_masked : classes.root;
  const pageClass = isMasked ? classes.page_masked : classes.page;

  const [modal, setModal] = useState(null);

  useScrollLock(isMasked);

  useEffect(() => {
    // sendPageView(window.location.pathname);

    let timeout;
    timeout = setTimeout(() => {
      if (!window['isPreRendering']) setModal(true);
    }, 3000);
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <main className={rootClass}>
      <Header />
      <div className={pageClass}>{children}</div>
      <Footer />
      {/* {modal && (
        <Suspense>
          <HomepageModal />
        </Suspense>
      )} */}
    </main>
  );
};

export default Main;

// Main.propTypes = {
//     classes: shape({
//         page: string,
//         page_masked: string,
//         root: string,
//         root_masked: string
//     }),
//     isMasked: bool
// };
