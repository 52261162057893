import { useWindowSize } from "packages/framework/hooks/useWindowSize";
import {
  lazy,
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Link, useNavigate } from "react-router-dom";
import CartTrigger from "./cartTrigger";
import HeaderIcon from "./headerIcon";
import { Menu } from "react-feather";
import { useNavigationTrigger } from "packages/framework/hooks/Header/useNavigationTrigger";
import AuthModal from "../AuthComponent/AuthModal";
import { useAppContext } from "packages/framework/context/app";
import { useUserState } from "packages/framework/context/user";
import { useAuthModal } from "packages/framework/hooks/AuthModal/useAuthModal";
import { useAuthModalDrawer } from "../AuthComponent/useAuthModalDrawer";
import { useCompareState } from "packages/framework/context/compareContext";
import CmsBlockGroup from "packages/framework/components/CmsBlock";
import { gql, useQuery } from "@apollo/client";
import { generateUrl } from "packages/framework/util/imageUtils";
//import SearchBar from 'packages/framework/components/SearchBar';
import HeaderBottom from "./headerBottom";

import "./header.css";
import "./header.scss";
const SearchBar = lazy(() => import("../SearchBar"));

const HeaderLogo = (props) => {
  const { data, loading } = useQuery(
    gql`
      query {
        storeConfig {
          logo_width
          logo_height
          store_code
          header_logo_src
        }
      }
    `,
    {
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-first",
    }
  );

  const src = data?.storeConfig.header_logo_src;
  const url = useMemo(
    () => (loading ? null : generateUrl(src, "image-logo")(null, null)),
    [src, loading]
  );
  return <img src={url} />;
};

const HeaderMiddle = (props) => {
  const { isDesktop, isMobile } = useWindowSize();

  const { handleOpenNavigation } = useNavigationTrigger();
  const { isSignedIn, currentUser } = useUserState();
  const state = useCompareState();
  const { handleSignOut } = useAuthModal({});

  const { toggleDrawer } = useAppContext();
  const talonProps = useAuthModalDrawer();
  const { handleClose, isOpen } = talonProps;

  const handleAuthModal = useCallback(() => {
    toggleDrawer("authModal.openClose");
  }, [toggleDrawer]);

  // const { styles } = useXsearchHeader(); //need to be checked for search
  const navigate = useNavigate();

  return (
    <>
      <div className="w-full bg-brandWhite">
        <div className="2xl:container container  md:pt-4 grid grid-cols-1 md:grid-cols-12 lg:pb-2">
          <div className="cursor-pointer lg:order-1 col-span-12 md:col-span-3 lg:col-span-2 text-center md:text-left mobile-span-12">
            {isDesktop ? null : (
              <div
                className="bg-brandWhite rounded-md mr-2 lg:hidden"
                onClick={handleOpenNavigation}
              >
                <Menu
                  width={"auto"}
                  height={35}
                  style={{
                    padding: "7px 8.5px",
                  }}
                />
              </div>
            )}
            <Link to="/" className=" w-[170px] h-[25px] block">
              <HeaderLogo />
            </Link>
          </div>
          <div className="lg:order-3 col-span-12 md:col-span-9 lg:col-span-2 md:py-0 pt-0 lg-order-1">
            <div className="flex justify-center md:justify-end lg:justify-start xl:justify-end">
              <div className="group relative width-80 disply-flx-align">
                {isSignedIn ? (
                  <>
                    <span className="username-auth font-bold flex">
                      {currentUser?.firstname}
                    </span>
                    <HeaderIcon
                      className=""
                      w="28px"
                      h="35px"
                      x="-77px"
                      y="-16px"
                    ></HeaderIcon>
                  </>
                ) : (
                  <Link to="#" className="font-bold" onClick={handleAuthModal}>
                    <HeaderIcon
                      className=""
                      w="28px"
                      h="35px"
                      x="-77px"
                      y="-16px"
                    ></HeaderIcon>
                  </Link>
                )}

                <div
                  className={`${
                    isSignedIn && "group-hover:block"
                  } absolute top-full hidden px-[30px] py-5  w-max min-w-[180px] text-[13px]  h-auto nav-items bg-brandWhite z-[100] shadow-md border border-solid border-[#ddd] leftside`}
                >
                  <div>
                    <Link
                      to="/account/my-account"
                      className="py-[5px] text-brandBlack hover:text-orange block"
                    >
                      My Account
                    </Link>
                  </div>
                  <div>
                    <Link
                      to="/account/wishlist"
                      className="py-[5px] text-brandBlack hover:text-orange block"
                    >
                      My Wishlist
                    </Link>
                  </div>
                  <div>
                    <a
                      onClick={handleSignOut}
                      className=" bg-orange font-bold h-9 mt-[10px] px-5 text-brandWhite text-[13px]  flex items-center cursor-pointer uppercase justify-center hover:bg-[#e53600]"
                    >
                      <span>Sign Out</span>
                    </a>
                  </div>
                </div>
              </div>

              {/* Move this to Cart Trigger */}
              {/* <HeaderIcon className='mb-3' w="35px" h="34px" x="-124px" y="-16px">
              <Link to='#' className='' >My Cart - 0 item</Link>
              <Link to='#' className='font-bold'>$0.00</Link>
            </HeaderIcon> */}

              <CartTrigger />
            </div>
          </div>
          <div className="lg:order-2 col-span-12 my-2 lg:my-0 flex xl:pl-0 px-2 lg:col-span-6 xl:col-span-6 lg:px-[10px] lg-order-7">
            <HeaderBottom />

            <Suspense>
              <SearchBar />
            </Suspense>
          </div>
        </div>
      </div>
      <AuthModal showModal={isOpen} setOpenModal={handleClose} />
    </>
  );
};

export default HeaderMiddle;
