import Tabs from '../Tabs';
import { LogInComponent } from './LogInComponent';
import { RegisterComponent } from './RegisterComponent';
import './authComponent.scss';

export const AuthComponent = ({ close }) => {
  const tabsArray = [
    {
      title: 'Log in',
      className: 'login-form',
      render: () => {
        return <LogInComponent close={close} className="login-form" />;
      },
    },
    {
      title: 'Register',
      className: 'register-form',
      render: () => (
        <RegisterComponent close={close} className="register-form" />
      ),
    },
  ];

  return (
    <div className="max-w-[100%] md:max-w-[100%] mx-auto">
      <Tabs tabs={tabsArray} className={`flex auth-btn`} />
    </div>
  );
};
