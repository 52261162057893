import { WindowSizeContextProvider } from 'packages/framework/hooks/useWindowSize';
import { ToastContextProvider } from 'packages/framework/Toasts';
import StoreContextProvider  from 'packages/framework/StoreContextProvider';
import AmSearchProProvider from 'src/context';
// import {
//     PeregrineContextProvider as Peregrine,
//     ToastContextProvider,
//     WindowSizeContextProvider
// } from '@magento/peregrine';
import LocaleProvider from './localeProvider';

/**
 * List of context providers that are required to run Venia
 *
 * @property {React.Component[]} contextProviders
 */
const contextProviders = [
  LocaleProvider,
  StoreContextProvider,
  WindowSizeContextProvider,
  ToastContextProvider,
  AmSearchProProvider
];

const ContextProvider = ({ children }) => {
  return contextProviders.reduceRight((memo, ContextProvider) => {
    return <ContextProvider>{memo}</ContextProvider>;
  }, children);
};

export default ContextProvider;
