import AppContextProvider from '../context/app';
import CartContextProvider from '../context/cart';
import CatalogContextProvider from '../context/catalog';
// import CheckoutContextProvider from '../context/checkout';
import ErrorContextProvider from '../context/unhandledErrors';
import RootComponentsProvider from '../context/rootComponents';
import UserContextProvider from '../context/user';
import AmAdvancedReviewsProvider from '../context/socialLogin';
import CompareContextProvider from '../context/compareContext';

/**
 * List of essential context providers that are required to run Peregrine
 *
 * @property {React.Component[]} contextProviders
 */
const contextProviders = [
  ErrorContextProvider,
  AppContextProvider,
  CompareContextProvider,
  UserContextProvider,
  CatalogContextProvider,
  CartContextProvider,
  AmAdvancedReviewsProvider,
  // CheckoutContextProvider,
  RootComponentsProvider,
];

const StoreContextProvider = ({ children }) => {
  return contextProviders.reduceRight((memo, ContextProvider) => {
    return <ContextProvider>{memo}</ContextProvider>;
  }, children);
};

export default StoreContextProvider;
