import React from 'react';

type Props = {
  type?: string;
  placeHolder?: string;
  icon?: string;
  name?: string;
  ref: React.RefObject<HTMLInputElement>;
  className?: string;
  label?: string;
  id?: string;
  value?: string;
  disabled?: boolean;
  readOnly?: boolean;
  maxLength?: any;
  error?: any;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onInput?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  // onKeyDown?: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const MobileNumberField = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      className = '',
      label = '',
      name,
      placeHolder = '',
      type = 'text',
      id,
      value,
      disabled = false,
      onChange,
      onInput,
      maxLength,
      readOnly,
      error,
    },
    ref,
  ) => {
    return (
      <div className={`flex flex-col ${className}`}>
        <label className="m-0 form-label" htmlFor={id}>
          {label}
        </label>
        <div className="control">
          <select>
            <option
              defaultChecked
              value="91"
              data-display-text="India&nbsp; +91"
              data-display-selection="IN&nbsp; +91"
            >
              +91
            </option>
          </select>
          <input
            disabled={disabled}
            className={`input ${error ? 'comm-error' : ''}`}
            type={type}
            name={name}
            id={id}
            ref={ref}
            placeholder={placeHolder}
            defaultValue={value}
            onChange={onChange}
            maxLength={maxLength}
            readOnly={readOnly}
            onInput={onInput}
            inputMode="numeric"
          />
        </div>
        {error && <div className="comm-error">{error}</div>}
      </div>
    );
  },
);

const FormInput = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      className = '',
      label = '',
      name = '',
      placeHolder = '',
      type = 'text',
      id = '',
      value,
      disabled = false,
      onChange,
      // onKeyDown,
      onInput,
      maxLength,
      readOnly,
      error,
    },
    ref,
  ) => (
    <div className={`flex flex-col ${className}`}>
      {label && (
        <label className="m-0 form-label" htmlFor={id}>
          {label}
        </label>
      )}
      <input
        disabled={disabled}
        className={`input ${error ? 'comm-error' : ''}`}
        type={type}
        name={name}
        id={id}
        ref={ref}
        placeholder={placeHolder}
        defaultValue={value}
        onChange={onChange}
        // onKeyDown={onKeyDown}
        maxLength={maxLength}
        readOnly={readOnly}
        onInput={onInput}
        // autoComplete='off'
      />
      {error && <div className="comm-error">{error}</div>}
    </div>
  ),
);

const CheckBox = React.forwardRef<HTMLInputElement, Props>(
  ({ id, name, label, onChange, className }, ref) => (
    <div className="d-flex align-items-center mt-3">
      <input
        className={`green-checkbox ${className}`}
        type="checkbox"
        name={name}
        id={id}
        ref={ref}
        onChange={onChange}
        defaultChecked={true}
      />
      <label className="ml-2 mb-0" htmlFor={id}>
        {label}
      </label>
    </div>
  ),
);

const Form = {
  FormInput,
  CheckBox,
  MobileNumberField,
};

export default Form;
