import { gql } from '@apollo/client';

import { GiftCardFragment } from './GiftCards/giftCardFragments.gql';
import { ProductListingFragment } from './ProductListing/productListingFragments.gql';
import { PriceSummaryFragment } from 'packages/framework/hooks/CartPage/PriceSummary/priceSummaryFragments.gql';
import { AppliedCouponsFragment } from './PriceAdjustments/CouponCode/couponCodeFragments.gql';
import { ShippingMethodsCartFragment } from './PriceAdjustments/ShippingMethods/shippingMethodsFragments.gql';

export const CartPageFragment = gql`
    fragment CartPageFragment on Cart {
        id
        total_quantity
        ...AppliedCouponsFragment
        ...GiftCardFragment
        ...ProductListingFragment
        ...PriceSummaryFragment
        ...ShippingMethodsCartFragment
    }
    ${AppliedCouponsFragment}
    ${GiftCardFragment}
    ${ProductListingFragment}
    ${PriceSummaryFragment}
    ${ShippingMethodsCartFragment}
`;
