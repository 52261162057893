import FormError from 'packages/framework/components/FormError/formError';
import { MutableRefObject, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import Button from 'components/Button';
import Form from 'src/components/Form';
import '../authComponent.scss';
import InputOTP from '../InputOTP';
import { SocialComponent } from '../SocialComponent';
import { GET_CART_DETAILS_QUERY } from './signIn.gql';

import forgotPasswordOperations from './forgotPassword.gql';
import FormSubmissionSuccessful from 'packages/framework/components/ForgotPassword/FormSubmissionSuccessful';
import validateEmail from 'packages/framework/util/validators/validateEmail';
import { useSignIn } from 'src/hooks/SignIn/useSignIn';
import { useForgotPassword } from 'src/hooks/ForgotPassword/useForgotPassword';
import SocialAuthentication from '../SocialAuthComponent/SocialAuthentication';

const ForgotPassword = ({ close, setLoginWay }) => {
  const methods = useForm();
  const {
    register,
    // handleSubmit,
    reset,
    formState: { errors: formErrors },
  } = methods;

  const handleForgotPasswordSubmit = () => {
    setTimeout(() => {
      reset();
      close();
      setLoginWay({
        login_password: true,
        login_otp: false,
        forgot_password: false,
      });
    }, 2000);
  };

  const talonProps = useForgotPassword({
    onCancel: {},
    onSubmit: handleForgotPasswordSubmit,
    ...forgotPasswordOperations,
  });

  const {
    forgotPasswordEmail,
    errors,
    handleCancel,
    handleFormSubmit,
    hasCompleted,
    isResettingPassword,
    recaptchaWidgetProps,
  } = talonProps;

  const onSubmitWithPassword = data => {
    handleFormSubmit(data);
  };

  return (
    <form
      onSubmit={methods.handleSubmit(onSubmitWithPassword)}
      className="py-4"
    >
      {hasCompleted && <FormSubmissionSuccessful email={forgotPasswordEmail} />}
      <FormError allowErrorMessages errors={Array.from(errors.values())} />
      <Form.FormInput
        name="email"
        label="Email id"
        id="email"
        type="email"
        placeHolder="Thomas@gmail.com"
        className="login-field"
        {...register('email', validateEmail)}
        error={formErrors.email?.message}
      />
      <div className="text-red-600 font-bold text-xs mt-1">
        Reset link will be emailed here
      </div>

      <div className="items-center flex gap-6 md:gap-6 mt-4">
        <button
          disabled={isResettingPassword}
          type="submit"
          className="buy_now_btn disabled:text-[#acacac] disabled:opacity-75 disabled:cursor-not-allowed px-6 flex items-center justify-center bg-orange font-bold rounded h-[42px] text-xs text-white"
        >
          Request reset link
        </button>
        <a
          onClick={() =>
            setLoginWay({
              login_password: true,
              login_otp: false,
              forgot_password: false,
            })
          }
          className="cursor-pointer uppercase text-xs text-orange"
        >
          BACK
        </a>
      </div>
    </form>
  );
};

const LoginViaPassword = ({ close, setLoginWay }) => {
  const navigate = useNavigate();

  const methods = useForm();
  const {
    register,
    // handleSubmit,
    reset,
    formState: { errors: formErrors },
  } = methods;

  const handleLoginSubmit = () => {
    close();
    reset();
  };

  const talonProps = useSignIn({
    getCartDetailsQuery: GET_CART_DETAILS_QUERY,
    setDefaultUsername: {},
    showCreateAccount: {},
    showForgotPassword: {},
    onSubmit: handleLoginSubmit,
    onCancel: {},
  });

  const {
    errors,
    handleCreateAccount,
    handleForgotPassword,
    handleSubmit,
    isBusy,
    // setFormApi,
    // recaptchaWidgetProps,
  } = talonProps;

  const onSubmitWithPassword = data => {
    handleSubmit(data);
  };

  return (
    <form
      onSubmit={methods.handleSubmit(onSubmitWithPassword)}
      className="py-4"
    >
      <FormError allowErrorMessages errors={Array.from(errors.values())} />
      <Form.FormInput
        name="email"
        label="Email id"
        id="email"
        type="email"
        placeHolder="Thomas@gmail.com"
        className="login-field"
        {...register('email', {
          required: {
            value: true,
            message: 'Email is required',
          },
          pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            message: 'Invalid email address',
          },
        })}
        error={formErrors.email?.message}
      />
      <Form.FormInput
        name="password"
        label="Password"
        id="password"
        type="password"
        placeHolder="Password"
        className="login-field"
        {...register('password', {
          required: {
            value: true,
            message: 'Password is required',
          },
        })}
        error={formErrors.password?.message}
      />
      <a
        onClick={() =>
          setLoginWay({
            login_password: false,
            login_otp: false,
            forgot_password: true,
          })
        }
        className="cursor-pointer w-full uppercase block font-light text-left mt-2 mb-4 text-[10px] text-brandBlack"
      >
        Forgot password
      </a>
      <div className="items-center grid grid-cols-2 md:grid-cols-2 gap-6 md:gap-6 mt-4">
        <button
          disabled={isBusy}
          type="submit"
          className="buy_now_btn disabled:text-[#acacac] disabled:opacity-75 disabled:cursor-not-allowed flex items-center justify-center bg-orange font-bold rounded h-[42px] text-xs text-white"
        >
          Login
        </button>
        <a
          onClick={() =>
            setLoginWay({
              login_password: false,
              login_otp: true,
              forgot_password: false,
            })
          }
          className="cursor-pointer w-full uppercase text-xs text-brandBlack"
        >
          Login via otp
        </a>
      </div>
    </form>
  );
};

const LoginViaOTP = ({ setLoginWay, setMobileNumber }) => {
  const methods = useForm();
  const {
    register,
    formState: { errors: formErrors },
  } = methods;

  const talonProps = useSignIn({
    getCartDetailsQuery: GET_CART_DETAILS_QUERY,
    setDefaultUsername: {},
    showCreateAccount: {},
    showForgotPassword: {},
    onSubmit: {},
    onCancel: {},
  });

  const { errors, handleSubmitOTP, createLoginOtpLoading } = talonProps;

  const onSubmitWithOTP = async data => {
    const result = await handleSubmitOTP(data);
    result && setMobileNumber(data.mobilenumber);
  };

  return (
    <form onSubmit={methods.handleSubmit(onSubmitWithOTP)} className="py-4">
      <FormError allowErrorMessages errors={Array.from(errors.values())} />
      <Form.MobileNumberField
        name="mobilenumber"
        label="Phone no"
        id="mobilenumber"
        type="tel"
        placeHolder="999 999 9999"
        {...register('mobilenumber', {
          required: {
            value: true,
            message: 'Mobile Number is required',
          },
          pattern: {
            value: /^[0-9\b]+$/,
            message: 'Invalid Mobile number',
          },
          minLength: {
            value: 10,
            message: 'Mobile number is not valid',
          },
          maxLength: {
            value: 10,
            message: 'Mobile number is not valid',
          },
        })}
        className="login-otp-field"
        error={formErrors.mobilenumber?.message}
      />

      <div className="items-center grid grid-cols-2 md:grid-cols-2 gap-6 md:gap-6 mt-8">
        <button
          disabled={createLoginOtpLoading}
          type="submit"
          className="buy_now_btn disabled:text-[#acacac] disabled:opacity-75 disabled:cursor-not-allowed flex items-center justify-center bg-orange font-bold rounded h-[42px] text-xs text-white"
        >
          Generate OTP
        </button>
        <a
          onClick={() =>
            setLoginWay({
              login_otp: false,
              login_password: true,
              forgot_password: false,
            })
          }
          className="cursor-pointer w-full uppercase text-xs text-brandBlack"
        >
          Login via email
        </a>
      </div>
    </form>
  );
};

const VerifyOTP = ({ close, mobileNumber, setLoginWay, setMobileNumber }) => {
  const methods = useForm({
    mode: 'onChange',
  });

  const {
    register,
    reset,
    setValue,
    formState: { errors: formErrors },
  } = methods;

  const handleLoginSubmit = () => {
    close();
    reset();
  };

  const talonProps = useSignIn({
    getCartDetailsQuery: GET_CART_DETAILS_QUERY,
    setDefaultUsername: {},
    showCreateAccount: {},
    showForgotPassword: {},
    onSubmit: handleLoginSubmit,
    onCancel: {},
  });

  const { errors, handleVerifyOTP, createLoginOtpVerifyLoading, isBusy } = talonProps;

  const onVerifyOTP = async data => {
    const result = await handleVerifyOTP(data);
    console.log('result', result);
    reset();
  };

  return (
    <div className="flex flex-col login-otp-field">
      <FormError allowErrorMessages errors={Array.from(errors.values())} />
      <label className="m-0 form-label" htmlFor={mobileNumber}>
        Phone no
      </label>
      <div className="control mobile-number-control">
        <select>
          <option
            defaultChecked
            value="91"
            data-display-text="India&nbsp; +91"
            data-display-selection="IN&nbsp; +91"
          >
            +91
          </option>
        </select>
        <div className="input flex items-center">{mobileNumber}</div>
      </div>

      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onVerifyOTP)}>
          <input
            type="hidden"
            name="mobilenumber"
            {...register('mobilenumber')}
          />
          <Form.FormInput
            name="otp"
            label="OTP"
            id="otp"
            type="otp"
            placeHolder="Please enter 4 digit otp"
            className="login-field mt-4"
            {...register('otp', {
              required: {
                value: true,
                message: 'OTP is required',
              },
              pattern: {
                value: /^[0-9]{4}$/,
                message: 'Please enter 4 digit OTP',
              },
            })}
            error={formErrors.otp?.message}
          />
          {/* Commenting as some issue when re-submit */}
          {/* <label
            className="block text-left py-2.5 text-xs text-[#ededed] form-label"
            htmlFor={mobileNumber}
          >
            Enter OTP
          </label>
          <div className="items-center grid grid-cols-1 md:grid-cols-2 gap-10">
            <InputOTP phone={mobileNumber} />
            <a className="cursor-pointer w-full uppercase text-xs text-[#EDEDED]">
              Resend OTP
            </a>
          </div> */}
          <div className="items-center grid grid-cols-1 md:grid-cols-2 gap-10 mt-8">
            <button
              disabled={createLoginOtpVerifyLoading || isBusy}
              type="submit"
              onClick={() => setValue('mobilenumber', mobileNumber)}
              className="buy_now_btn disabled:text-[#acacac] disabled:opacity-75 disabled:cursor-not-allowed flex items-center justify-center uppercase bg-orange font-bold rounded h-[42px] text-xs text-white"
            >
              Verify OTP
            </button>
            <a
              onClick={() => {
                setMobileNumber(false);
                setLoginWay({
                  login_otp: false,
                  login_password: true,
                  forgot_password: false,
                });
              }}
              className="cursor-pointer w-full uppercase text-xs text-orange"
            >
              Login via email
            </a>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export const LogInComponent = ({ close, className = '' }) => {
  const [loginWay, setLoginWay] = useState({
    login_password: true,
    login_otp: false,
    forgot_password: false,
  });

  const [mobileNumber, setMobileNumber] = useState(false);

  return (
    <div className={`${className} bg-footerBackground px-8 py-4 rounded-b`}>
      {loginWay?.login_password && (
        <LoginViaPassword close={close} setLoginWay={setLoginWay} />
      )}

      {loginWay?.login_otp && !mobileNumber && (
        <LoginViaOTP
          setLoginWay={setLoginWay}
          setMobileNumber={setMobileNumber}
        />
      )}

      {loginWay?.login_otp && mobileNumber && (
        <VerifyOTP
          mobileNumber={mobileNumber}
          setLoginWay={setLoginWay}
          setMobileNumber={setMobileNumber}
          close={close}
        />
      )}

      {loginWay?.forgot_password && (
        <ForgotPassword close={close} setLoginWay={setLoginWay} />
      )}

      {!loginWay?.forgot_password && (
        <p className="w-full text-center text-sm text-brandBlack pb-4">Or</p>
      )}

      {/* {!loginWay?.forgot_password && <SocialComponent />} */}
      {!loginWay?.forgot_password && (
        <SocialAuthentication mode="popup" isPopup />
      )}
    </div>
  );
};
