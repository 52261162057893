import FormError from 'packages/framework/components/FormError/formError';
// import { useCreateAccount } from 'packages/framework/hooks/CreateAccount/useCreateAccount';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import Button from 'components/Button';
import Form from 'src/components/Form';
import InputOTP from '../InputOTP';
import { SocialComponent } from '../SocialComponent';
import { useNavigate } from 'react-router-dom';

import '../authComponent.scss';
import { useCreateAccount } from 'src/hooks/CreateAccount/useCreateAccount';
import SocialAuthentication from '../SocialAuthComponent/SocialAuthentication';

const CommonInputFields = ({ register, errors, registerWay }) => {
  return (
    <>
      <div className="grid grid-cols-2 md:grid-cols-2 gap-4">
        <Form.FormInput
          name="firstname"
          label="First name"
          id="firstname"
          type="firstname"
          placeHolder="Thomas"
          className="login-field"
          {...register('firstname', {
            required: {
              value: true,
              message: 'First name is required',
            },
            pattern: {
              value:  /^[a-zA-Z]+$/,
              message: 'Invalid First Name, use only alphabets',
            },
          })}
          error={errors.firstname?.message}
        />
        <Form.FormInput
          name="lastname"
          label="Last name"
          id="lastname"
          type="lastname"
          placeHolder="Walker"
          className="login-field"
          {...register('lastname', {
            required: {
              value: true,
              message: 'Last name is required',
            },
            pattern: {
              value:  /^[a-zA-Z]+$/,
              message: 'Invalid Last Name, use only alphabets',
            },
          })}
          error={errors.lastname?.message}
        />
      </div>
      <Form.FormInput
        name="email"
        label="Email id"
        id="email"
        type="email"
        placeHolder="Thomas@gmail.com"
        className="login-field"
        {...register('email', {
          required: {
            value: true,
            message: 'Email is required',
          },
          pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            message: 'Invalid email address',
          },
        })}
        error={errors.email?.message}
      />
      {/* Commenting for now in register as magento code not written */}
      <Form.MobileNumberField
        name="mobilenumber"
        label="Phone no"
        id="mobilenumber"
        type="tel"
        placeHolder="999 999 9999"
        className="login-otp-field"
        {...register('mobilenumber', {
          required: {
            value: true,
            message: 'Mobile Number is required',
          },
          pattern: {
            value: /^[0-9\b]+$/,
            message: 'Invalid Mobile number',
          },
          minLength: {
            value: 10,
            message: 'Mobile number is not valid',
          },
          maxLength: {
            value: 10,
            message: 'Mobile number is not valid',
          },
        })}
        error={errors.mobilenumber?.message}
      />
      {registerWay?.register_password && (
        <Form.FormInput
          name="password"
          label="Password"
          id="password"
          type="password"
          placeHolder="Password"
          className="login-field"
          {...register('password', {
            required: {
              value: true,
              message: 'Password is required',
            },
          })}
          error={errors.password?.message}
        />
      )}
      <div className="checkbox">
        <input
          type="checkbox"
          defaultChecked={true}
          {...register('checkbox')}
        />
        <span>Subscribe to newsletter</span>
      </div>
    </>
  );
};

const RegisterViaPassword = ({ close, setRegisterWay, registerWay }) => {
  const navigate = useNavigate();

  const methods = useForm();
  const {
    register,
    reset,
    formState: { errors: formErrors },
  } = methods;

  const handleRegisterSubmit = () => {
    close();
    reset();
    navigate('/account/my-account');
  };

  const talonProps = useCreateAccount({
    initialValues: {},
    onSubmit: handleRegisterSubmit,
    onCancel: {},
  });

  const { errors, handleSubmit, isDisabled } = talonProps;

  const onSubmitWithPassword = data => {
    console.log(data);
    handleSubmit(data);
  };

  return (
    <form
      className="py-4"
      onSubmit={methods.handleSubmit(onSubmitWithPassword)}
    >
      <FormError allowErrorMessages errors={Array.from(errors.values())} />
      <CommonInputFields
        register={register}
        errors={formErrors}
        registerWay={registerWay}
      />

      <div className="items-center grid grid-cols-2 md:grid-cols-2 gap-6 md:gap-6 mt-4">
        <button
          disabled={isDisabled}
          type="submit"
          className="buy_now_btn disabled:text-[#acacac] disabled:opacity-75 disabled:cursor-not-allowed flex items-center justify-center uppercase bg-orange font-bold rounded h-[42px] text-xs text-white"
        >
          Submit
        </button>
        <a
          onClick={() =>
            setRegisterWay({ register_password: false, register_otp: true })
          }
          className="cursor-pointer w-full uppercase text-xs text-brandBlack"
        >
          Sign up via OTP
        </a>
      </div>
    </form>
  );
};

const RegisterViaOTP = ({
  setRegisterWay,
  registerWay,
  setMobileNumber,
  setFormVerifyData,
}) => {
  const methods = useForm();

  const {
    register,
    reset,
    formState: { errors: formErrors },
  } = methods;

  const talonProps = useCreateAccount({
    initialValues: {},
    onSubmit: {},
    onCancel: {},
  });

  const { errors, handleSubmitOTP, createAccountOtpLoading } = talonProps;

  const onSubmitWithOTP = async data => {
    setFormVerifyData(data);
    const result = await handleSubmitOTP(data);
    if (result) {
      setFormVerifyData(data);
      setMobileNumber(data.mobilenumber);
      reset();
    }
  };

  return (
    <form className="py-4" onSubmit={methods.handleSubmit(onSubmitWithOTP)}>
      <FormError errors={Array.from(errors.values())} />
      <CommonInputFields
        register={register}
        errors={formErrors}
        registerWay={registerWay}
      />
      <div className="items-center grid grid-cols-2 md:grid-cols-2 gap-6 md:gap-6 mt-4">
        <button
          disabled={createAccountOtpLoading}
          type="submit"
          className="buy_now_btn disabled:text-[#acacac] disabled:opacity-75 disabled:cursor-not-allowed flex items-center justify-center uppercase bg-orange font-bold rounded h-[42px] text-xs text-white"
        >
          Generate OTP
        </button>
        <a
          onClick={() =>
            setRegisterWay({ register_password: true, register_otp: false })
          }
          className="cursor-pointer w-full uppercase text-[10px] text-white"
        >
          Sign up via Password
        </a>
      </div>
    </form>
  );
};

const VerifyOTP = ({
  mobileNumber,
  formVerifyData,
  setRegisterWay,
  setMobileNumber,
  close,
}) => {
  let navigate = useNavigate();
  const methods = useForm({
    mode: 'onChange',
  });

  const {
    register,
    reset,
    setValue,
    formState: { errors: formErrors },
  } = methods;

  const handlePostSubmit = () => {
    close();
    reset();
    navigate('/account/my-account');
  };

  const talonProps = useCreateAccount({
    initialValues: {},
    onSubmit: handlePostSubmit,
    onCancel: {},
  });

  const { errors, handleVerifyOTP, isLoading } = talonProps;

  const onVerifyOTP = async data => {
    //commenting as inputOTP compoenent not there
    // let otp = `${data.num1}${data.num2}${data.num3}${data.num4}`;
    let otp = data.otp;
    const validate = await handleVerifyOTP(formVerifyData, otp);
  };

  return (
    <div className="flex flex-col login-otp-field">
      <FormError errors={Array.from(errors.values())} />
      <label className="m-0 form-label" htmlFor={mobileNumber}>
        Phone no
      </label>
      <div className="control mobile-number-control">
        <select>
          <option
            defaultChecked
            value="91"
            data-display-text="India&nbsp; +91"
            data-display-selection="IN&nbsp; +91"
          >
            +91
          </option>
        </select>
        <div className="input flex items-center">{mobileNumber}</div>
      </div>

      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onVerifyOTP)}>
          <input
            type="hidden"
            name="mobilenumber"
            {...register('mobilenumber')}
          />
          <Form.FormInput
            name="otp"
            label="OTP"
            id="otp"
            type="otp"
            placeHolder="Please enter 4 digit otp"
            className="login-field mt-4"
            {...register('otp', {
              required: {
                value: true,
                message: 'OTP is required',
              },
              pattern: {
                value: /^[0-9]{4}$/,
                message: 'Please enter 4 digit OTP',
              },
            })}
            error={formErrors.otp?.message}
          />
          {/* Commenting as some issue when re-submit */}
          {/* <label
            className="block text-left py-2.5 text-xs text-[#ededed] form-label"
            htmlFor={mobileNumber}
          >
            Enter OTP
          </label> */}
          {/* <div className="items-center grid grid-cols-1 md:grid-cols-2 gap-10">
            <InputOTP phone={mobileNumber} />
            <a className="cursor-pointer w-full uppercase text-xs text-[#EDEDED]">
              Resend OTP
            </a>
          </div> */}
          <div className="items-center grid grid-cols-1 md:grid-cols-2 gap-10 mt-8">
            <button
              disabled={isLoading}
              type="submit"
              onClick={() => setValue('mobilenumber', mobileNumber)}
              className="buy_now_btn disabled:text-[#acacac] disabled:opacity-75 disabled:cursor-not-allowed flex items-center justify-center uppercase bg-orange font-bold rounded h-[42px] text-xs text-white"
            >
              Verify OTP
            </button>
            <a
              onClick={() => {
                setMobileNumber(false);
                setRegisterWay({
                  register_password: true,
                  register_otp: false,
                });
              }}
              className="cursor-pointer w-full uppercase text-[11px] text-orange"
            >
              Login via Password
            </a>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export const RegisterComponent = ({ close, className = '' }) => {
  const [formVerifyData, setFormVerifyData] = useState(null);
  const [registerWay, setRegisterWay] = useState({
    register_password: true,
    register_otp: false,
  });

  const [mobileNumber, setMobileNumber] = useState(false);

  return (
    <div className={`${className} bg-footerBackground px-8 py-4 rounded-b `}>
      {registerWay?.register_password && (
        <RegisterViaPassword
          setRegisterWay={setRegisterWay}
          registerWay={registerWay}
          close={close}
        />
      )}

      {registerWay?.register_otp && !mobileNumber && (
        <RegisterViaOTP
          setRegisterWay={setRegisterWay}
          registerWay={registerWay}
          setMobileNumber={setMobileNumber}
          setFormVerifyData={setFormVerifyData}
        />
      )}

      {registerWay?.register_otp && mobileNumber && (
        <VerifyOTP
          setRegisterWay={setRegisterWay}
          mobileNumber={mobileNumber}
          formVerifyData={formVerifyData}
          setMobileNumber={setMobileNumber}
          close={close}
        />
      )}

      <p className="w-full text-center text-sm text-brandBlack pb-4">Or</p>
      {/* <SocialComponent /> */}
      <SocialAuthentication mode="popup" isPopup />
    </div>
  );
};
