import { RegisterOptions } from 'react-hook-form';
import validateRequired from './validateRequired';
const validateEmail: RegisterOptions = {
  ...validateRequired,
  pattern: {
    value:
      // /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
      /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
    message: 'Invalid email address',
  },
};

export default validateEmail;
