import defaultOperations from 'src/queries/getConfigs.gql';
import { useItems } from './useItems';

export const useConfigs = () => {
  const {GET_CONFIGS} = defaultOperations;

  const {error, data: configs } = useItems({
    query: GET_CONFIGS
  });
  const { storeConfig } = configs || {};

  return {
    storeConfig,
    error
  };
};
