import React from 'react';
import { func, shape, string } from 'prop-types';
import { useCategoryTree } from 'packages/framework/hooks/CategoryTree';

import { useStyle } from '../../util/classify';
import Branch from './categoryBranch';
import Leaf from './categoryLeaf';
import defaultClasses from './categoryTree.module.scss';
import leftClasses from './categoryLeaf.module.scss';
import { Link } from 'react-router-dom';
const Tree = props => {
  const { categoryId, onNavigate, setCategoryId, updateCategories } = props;

  const talonProps = useCategoryTree({
    categoryId,
    updateCategories,
  });

  const { data, childCategories, categoryUrlSuffix } = talonProps;
  const classes = useStyle(defaultClasses, props.classes);
  const handleClick = () => {onNavigate();}

  // for each child category, render a direct link if it has no children
  // otherwise render a branch
  const branches = data
    ? Array.from(childCategories, childCategory => {
        const [id, { category, isLeaf }] = childCategory;
        return isLeaf ? (
          <Leaf
            key={id}
            category={category}
            onNavigate={onNavigate}
            categoryUrlSuffix={categoryUrlSuffix}
          />
        ) : (
          <Branch key={id} category={category} setCategoryId={setCategoryId} />
        );
      })
    : null;
  return (
    <div className={classes.root} data-cy="CategoryTree-root">
      <ul className={classes.tree}>
        <li className={leftClasses.root}>
          <Link
            className={leftClasses.target}
            data-cy="CategoryTree-Leaf-target"
            to="/"
            onClick={handleClick}
          >
            <span className={leftClasses.text}>Home</span>
          </Link>
        </li>
        {branches}
        <li className={leftClasses.root}>
          <Link
            className={leftClasses.target}
            data-cy="CategoryTree-Leaf-target"
            to="/blog"
            onClick={handleClick}
          >
            <span className={leftClasses.text}>Blog</span>
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default Tree;

Tree.propTypes = {
  categoryId: string,
  classes: shape({
    root: string,
    tree: string,
  }),
  onNavigate: func.isRequired,
  setCategoryId: func.isRequired,
  updateCategories: func.isRequired,
};
