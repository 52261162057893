import { useAppContext, useAppState } from 'packages/framework/context/app';

export const useAuthModalDrawer = () => {
  const { closeDrawer } = useAppContext();
  const { drawer } = useAppState();
  const isOpen = drawer === 'authModal.openClose';

  return {
    handleClose: closeDrawer,
    isOpen,
  };
};
