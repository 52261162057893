import { AuthComponent } from '.';
import { AuthWrapper } from './AuthWrapper';
import './authComponent.scss';

export default function AuthModal({ showModal, setOpenModal }) {
  const showHideClassName = showModal ? 'display-block' : 'hide-modal';

  const closeModal = () => {
    setOpenModal();
  };

  return (
    <AuthWrapper
      showHideClassName={showHideClassName}
      showModal={showModal}
      close={closeModal}
    >
      <AuthComponent close={closeModal} />
    </AuthWrapper>
  );
}
