import { RegisterOptions } from 'react-hook-form';
const validateRequired: RegisterOptions = {
 // required: true,
  required: {
    value: true,
    message: 'This is a required field',
  },
};

export default validateRequired;
