import { createContext, useContext } from 'react';

import { useAccordion } from 'packages/framework/hooks/Accordion/useAccordion';

import { useStyle } from 'packages/framework/util/classify';
import defaultClasses from './accordion.module.scss';

const AccordionContext = createContext(null);
const { Provider } = AccordionContext;

const Accordion = props => {
  const { canOpenMultiple = true, children } = props;

  // The talon is the source of truth for the context value.
  const talonProps = useAccordion({ canOpenMultiple, children });
  const { handleSectionToggle, openSectionIds } = talonProps;
  const contextValue = {
    handleSectionToggle,
    openSectionIds,
  };

  const classes = useStyle(defaultClasses, props.classes);

  return (
    <Provider value={contextValue}>
      <div className={classes.root}>{children}</div>
    </Provider>
  );
};

export default Accordion;

export const useAccordionContext = () => useContext(AccordionContext);
