import { ApolloProvider } from '@apollo/client';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { useAdapter } from 'packages/framework/hooks/Adapter/useAdapter';
import App, { AppContextProvider } from 'components/App';
import StoreCodeRoute from 'components/StoreCodeRoute';

const Adapter = props => {
  const talonProps = useAdapter(props);
  const { apolloProps, initialized, reduxProps, routerProps, urlHasStoreCode } =
    talonProps;

  // TODO: Replace with app skeleton. See PWA-547.
  if (!initialized) {
    return null;
  }

//   const children = props.children || <App />;
  const storeCodeRouteHandler = urlHasStoreCode ? <StoreCodeRoute /> : null;

  return (
    <ApolloProvider {...apolloProps}>
      <ReduxProvider {...reduxProps}>
        <BrowserRouter {...routerProps}>
          {storeCodeRouteHandler}
          <AppContextProvider>
            <App />
          </AppContextProvider>
        </BrowserRouter>
      </ReduxProvider>
    </ApolloProvider>
  );
};

export default Adapter;
