import Form from 'packages/framework/components/Form';
import validateEmail from 'packages/framework/util/validators/validateEmail';
import './middlefooter.scss';
import TextInput from 'src/components/TextInput/textInput';
import { useNewsletter } from 'src/hooks/Newsletter/useNewsletter';
import { useWindowSize } from 'packages/framework/hooks/useWindowSize';
import paymentImage from 'src/images/paymentpartners.png';


import { useAccordion } from 'packages/framework/hooks/Accordion/useAccordion';
import Accordion from 'src/components/Accordion/accordion';
import Section from 'src/components/Accordion/section';
const firstContent = 'First Content Here';
const secondContent = 'Second Contents Here';
const thirdContent = 'Third Contents Here';
import { FloatingWhatsApp } from 'react-floating-whatsapp';

const brandlist = [
  { name: 'Home', href: '/' },
  { name: 'Apple', href: '/apple' },
  { name: 'Oppo', href: '/oppo' },
  { name: 'Oneplus', href: '/oneplus' },
  { name: 'Samsung', href: '/samsung' },
  { name: 'Vivo', href: '/vivo' },
  { name: 'Xiaomi', href: '/xiaomi' },
  { name: 'Nothing', href: '/nothing' },
  { name: 'iQOO', href: '/iqoo' },
  { name: 'Realme', href: '/realme' },
  { name: 'Techno', href: '/techno' },
  { name: 'Just Corseca', href: '/speakers' },
];

const brands = [
  { name: 'Refurbished Apple Mobiles', href: '/refurbished-iphone-mobiles' },
  { name: 'Refurbished Oppo Mobiles', href: '/refurbished-oppo-mobiles' },
  { name: 'Refurbished OnePlus Mobiles', href: '/refurbished-oneplus-mobiles' },
  { name: 'Refurbished Samsung Mobiles', href: '/refurbished-samsung-mobiles' },
  { name: 'Refurbished Vivo Mobiles', href: '/refurbished-vivo-mobiles' },
  { name: 'Refurbished Xiaomi Mobiles', href: '/refurbished-xiomi-mobiles' },
  { name: 'Refurbished Realme Mobiles', href: '/realme' },
  { name: 'Just Corseca', href: '/speakers' },
  { name: 'Refurbished Nothing Mobiles', href: '/nothing' },
  { name: 'Refurbished iqoo Mobiles', href: '/iqoo' },
  { name: 'Refurbished Techno Mobiles', href: '/techno' },
   { name: 'Refurbished Laptop', href: '/refurbished-laptops' },
];

const shop = [
  { name: 'Latest', href: '/latest' },
  { name: 'Top Searches', href: '/other-categories/top-searches' },
  { name: 'New Arrivals', href: '/new-arrivals' },
  { name: 'Mobex Choice', href: '/mobex-choice' },
  { name: 'Best Sellers', href: '/best-sellers' },


];

const mobex = [
  { name: 'Shop', href: '/productlisting' },
  { name: 'Lightning Deals', href: '/lightning-deals' },
  { name: 'Deal of the day', href: '/deal-of-the-day' },
  { name: 'Great Festive Dhamaka Sale', href: '/great-festive-dhamaka-sale' },
  { name: 'Top Discount Deals', href: '/top-discount-deals' },
  { name: 'Repair', href: 'https://repair.mobex.in/' },
  { name: 'Green Impact', href: '/green-impact' },
];

const information = [
  { id: 'footer-about-us', name: 'About Us', href: '/about-us' },
  { id: 'footer-contact-us', name: 'Contact Us', href: '/contact' },
  {
    id: 'footer-privacy-policy',
    name: 'Privacy Policy',
    href: '/privacy-policy',
  },
  { id: 'footer-ecommerce-tnc', name: 'ECommerce T&C', href: '/ecommerce-tnc' },
  { id: 'footer-faqs', name: 'FAQs', href: '/faq' },
  { id: 'blog', name: 'Blog', href: '/blog' },
];

export default function MiddleFooter() {
   const { isDesktop } = useWindowSize();
  const talonProps = useNewsletter();
  const { addNewsletterEmail } = talonProps;

  const newsletterSubmit = data => {
    if (data?.email) {
      addNewsletterEmail(data?.email);
    }
  };

  return (
<>


<FloatingWhatsApp
        phoneNumber="7778035222"
        accountName=""
        allowEsc
        allowClickAway
        notification
        notificationSound
        statusMessage=""
       chatMessage="Hi, Welcome To Mobex India!  How can we make things better for you?"
        avatar="https://media.mobex.in/media/wysiwyg/Mobex-orange-Icon-logo_1.jpg"
      />
    <div className="middle-footer-wrapper bg-footerBackground desktop-view-accordian"> 
 

      <div className="container mx-auto ">
        <div className="pt-[43px] border-b border-solid border-[#ddd]">
          <div className="flex flex-wrap mx-[-15px] text-sm max-w-full ">
            <div className="w-full text-left flex-[0_0_100%] max-w-full md:flex-[0_0_50%] md:max-w-[50%] lg:flex-[0_0_16.6667%] lg:max-w-[16.6667%] relative  px-[15px]">
              <div className="mb-[30px] ">
                <div className=" text-brandBlack mb-[10px] font-semibold capitalize fonts-h5" >
                  Brands
                </div>
                <div className="">
                  <ul className="list-none">
                    {brands &&
                      brands.map((item, i) => (
                        <li key={`brand_${i}`} className="py-[5px]">
                          <a
                            className="hover:text-orange  text-brandBlack text-[14px] "
                            href={item?.href}
                          >
                            {item.name}
                          </a>
                        </li>
                      ))}
                  </ul>
                </div>
              </div>
            </div>
            <div className="w-full text-left flex-[0_0_100%] max-w-full md:flex-[0_0_50%] md:max-w-[50%] lg:flex-[0_0_16.6667%] lg:max-w-[16.6667%] relative  px-[15px]">
              <div className="mb-[30px] ">
                <div className=" text-brandBlack mb-[10px] font-semibold  capitalize fonts-h5">
                  Information
                </div>
                <div className="">
                  <ul>
                    {information &&
                      information.map((item, i) => (
                        <li key={`info_${i}`} id={item.id} className="py-[5px]">
                          <a
                            className="hover:text-orange   text-brandBlack text-[14px] "
                            href={item.href}
                          >
                            {item.name}
                          </a>
                        </li>
                      ))}
                  </ul>
                </div>
              </div>
            </div>
            <div className="w-full text-left flex-[0_0_100%] max-w-full md:flex-[0_0_50%] md:max-w-[50%] lg:flex-[0_0_16.6667%] lg:max-w-[16.6667%] relative  px-[15px]">
              <div className="mb-[30px] ">
                <div className=" text-brandBlack mb-[10px] font-semibold capitalize fonts-h5">
                  Mobex
                </div>
                <div className="">
                  <ul>
                    {mobex &&
                      mobex.map((item, i) => (
                        <li key={`link_${i}`} className="py-[5px]">
                          <a
                            className="hover:text-orange  text-brandBlack text-[14px] "
                            href={item.href}
                          >
                            {item.name}
                          </a>
                        </li>
                      ))}
                  </ul>
                </div>
              </div>
            </div>
            <div className="w-full text-left flex-[0_0_100%] max-w-full md:flex-[0_0_50%] md:max-w-[50%] lg:flex-[0_0_16.6667%] lg:max-w-[16.6667%] relative  px-[15px]">
              <div className="mb-[30px]">
                <div className=" text-brandBlack mb-[10px] font-semibold  capitalize fonts-h5">
                  Our Shop
                </div>
                <div className="">
                  <ul>
                    {shop &&
                      shop.map((item, i) => (
                        <li key={`shop_${i}`} className="py-[5px]">
                          <a
                            className="hover:text-orange  text-brandBlack text-[14px] "
                            href={item.href}
                          >
                            {item.name}
                          </a>
                        </li>
                      ))}
                  </ul>
                </div>
              </div>
            </div>
            <div className="w-full text-left flex-[0_0_100%] max-w-full lg:flex-[0_0_33.333%] lg:max-w-[33.333%] relative  px-[15px]">
             
              <div className="mb-[30px]">
                <div className="uppercase text-brandBlack mb-[10px] font-bold fonts-h5">
                  Follow Us
                </div>
                <ul className="list-none followus-icons flex items-center">
                  <li className="facebook social_block inline-block">
                    <a
                      href="https://www.facebook.com/mobex.ind"
                      target="_blank"
                      className="bg-[#3b5998]"
                      rel='no-follow'
                    >
                      <i className="text-[20px] fa-facebook fab"></i>
                    </a>
                  </li>
                  <li className="instagram social_block inline-block">
                    <a
                      href="https://www.instagram.com/mobex.ind/"
                      target="_blank"
                      className="bg-[#405de6]"
                      rel='no-follow'
                    >
                      <i className="text-[20px] fa-instagram fab"></i>
                    </a>
                  </li>
                  <li className="youtube social_block inline-block">
                    <a
                      href="https://www.youtube.com/channel/UC3IOwdHuAF-aiFxNOtNtx5w"
                      target="_blank"
                      className="bg-[#cd201f]"
                      rel='no-follow'
                    >
                      <i className="fa  fa-youtube fab">&nbsp;</i>
                    </a>
                  </li>
                  <li className="linkedin social_block inline-block">
                    <a
                      href="https://www.linkedin.com/company/mobexindia/"
                      className="bg-[#0177b5]"
                      rel='no-follow'
                    >
                      <i className="fa fa-linkedin fab">&nbsp;</i>
                    </a>
                  </li>
                </ul>
                <div className="footerimg">
                 <div
      className={`flex ${
        isDesktop ? 'flex-row justify-between pb-6' : 'flex-col'
      } items-center`}
      style={{ backgroundColor: 'brandWhite' }}
    >
      <img
        src={paymentImage}
        alt="Payment"
        className="pt-6  ml-0 w-[302px]"
      />
     
    </div>
    </div>
     <p
        className={` text-brandBlack text-xs ${
          isDesktop ? '' : 'mt-2 mb-6'
        } mr-4`}
      >
        Copyrights © 2023 Mobex.in, A Brand of <a  target="_blank" className="footerlink" href="https://qarmatek.com/"> Qarmatek Services Pvt. Ltd.</a>
      </p>
             
              </div>
            </div>
          </div>
        </div>

      {/*  <div className="py-[30px] text-brandBlack text-[13px]">
          <ul className="flex flex-wrap">
            {brandlist &&
              brandlist.map((brand, i) => (
                <li
                  key={`brand_list_${i}`}
                  className="after:mx-[10px] after:content-['/'] last:after:content-[]"
                >
                  <a className="hover:text-orange" href={brand?.href}>
                    {brand.name}
                  </a>
                </li>
              ))}
          </ul>
        </div>*/}
      </div>

    </div>
   
  <div className="middle-footer-wrapper bg-footerBackground mobile-view-accordian"> 
 

      <div className="w-[94%] mx-auto ">

  <Accordion canOpenMultiple={false}>
 
                <Section  title="Brands" className="ac-header"  id="Brands" isOpen={true}>
                  

  <ul className="list-none accordian-body" >
                    {brands &&
                      brands.map((item, i) => (
                        <li key={`brand_${i}`} className="py-[5px]">
                          <a
                            className="hover:text-orange  text-brandBlack text-[13px] "
                            href={item?.href}
                          >
                            {item.name}
                          </a>
                        </li>
                      ))}
                  </ul>

                   
                </Section>

            
           
  
                <Section  title="Information" id="Information">
                   <ul className="list-none accordian-body">
                    {information &&
                      information.map((item, i) => (
                        <li key={`info_${i}`} id={item.id} className="py-[5px]">
                          <a
                            className="hover:text-orange   text-brandBlack text-[13px] "
                            href={item.href}
                          >
                            {item.name}
                          </a>
                        </li>
                      ))}
                  </ul>
                </Section>
         
   
                <Section title="Mobex"  id="Mobex">
                    <ul className="list-none accordian-body">
                    {mobex &&
                      mobex.map((item, i) => (
                        <li key={`link_${i}`} className="py-[5px]">
                          <a
                            className="hover:text-orange  text-brandBlack text-[13px] "
                            href={item.href}
                          >
                            {item.name}
                          </a>
                        </li>
                      ))}
                  </ul>
             
           </Section>
   
                <Section title="Our Shop"  id="OurShop">
           <ul className="list-none accordian-body">
                    {shop &&
                      shop.map((item, i) => (
                        <li key={`shop_${i}`} className="py-[5px]">
                          <a
                            className="hover:text-orange  text-brandBlack text-[13px] "
                            href={item.href}
                          >
                            {item.name}
                          </a>
                        </li>
                      ))}
                  </ul>
           
            </Section>
    
                <Section title="Follow Us"  id="forth">
                <div className="foolow-us-padding">
          <ul className="list-none followus-icons flex items-center">
                  <li className="facebook social_block inline-block">
                    <a
                      href="https://www.facebook.com/mobex.ind"
                      target="_blank"
                      className="bg-[#3b5998]"
                      rel='no-follow'
                    >
                      <i className="text-[20px] fa-facebook fab"></i>
                    </a>
                  </li>
                  <li className="instagram social_block inline-block">
                    <a
                      href="https://www.instagram.com/mobex.ind/"
                      target="_blank"
                      className="bg-[#405de6]"
                      rel='no-follow'
                    >
                      <i className="text-[20px] fa-instagram fab"></i>
                    </a>
                  </li>
                  <li className="youtube social_block inline-block">
                    <a
                      href="https://www.youtube.com/channel/UC3IOwdHuAF-aiFxNOtNtx5w"
                      target="_blank"
                      className="bg-[#cd201f]"
                      rel='no-follow'
                    >
                      <i className="fa  fa-youtube fab">&nbsp;</i>
                    </a>
                  </li>
                  <li className="linkedin social_block inline-block">
                    <a
                      href="https://www.linkedin.com/company/mobexindia/"
                      className="bg-[#0177b5]"
                      rel='no-follow'
                    >
                      <i className="fa fa-linkedin fab">&nbsp;</i>
                    </a>
                  </li>
                </ul>
                <div className="footerimg">
                 <div
      className={`flex ${
        isDesktop ? 'flex-row justify-between pb-6' : 'flex-col'
      } items-center`}
      style={{ backgroundColor: 'brandWhite' }}
    >
      <img
        src={paymentImage}
        alt="Payment"
        className="pt-6  ml-0 w-[302px]"
      />
     
    </div>
    </div>
     <p
        className={` text-brandBlack text-xs ${
          isDesktop ? '' : 'mt-2 mb-6'
        } mr-4`}
      >
        Copyrights © 2023 Mobex.in, A Brand of <a  target="_blank" className="footerlink" href="https://qarmatek.com/"> Qarmatek Services Pvt. Ltd.</a>
      </p>
      </div>
          </Section>
            </Accordion>

 
      </div>
      </div>


</>


  );
}
