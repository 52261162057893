import { useCallback } from 'react';
import { useIntl } from 'react-intl';

import { useToasts } from 'packages/framework/Toasts/useToasts';
import useDelayedTransition from 'packages/framework/hooks/useDelayedTransition';
import { useApp } from 'packages/framework/hooks/App/useApp';

import globalCSS from '../../index.module.scss';
import { HeadProvider, StoreTitle } from '../Head';
import Main from '../Main';
import Mask from '../Mask';
import Navigation from '../Navigation';
import Routes from '../Routes';
import ToastContainer from '../ToastContainer';
import Icon from '../Icon';

import {
    AlertCircle as AlertCircleIcon,
    CloudOff as CloudOffIcon,
    Wifi as WifiIcon
} from 'react-feather';

const OnlineIcon = <Icon src={WifiIcon} attrs={{ width: 18 }} />;
const OfflineIcon = <Icon src={CloudOffIcon} attrs={{ width: 18 }} />;
const ErrorIcon = <Icon src={AlertCircleIcon} attrs={{ width: 18 }} />;

const App = props => {
    const { markErrorHandled, renderError, unhandledErrors } = props;
    const { formatMessage } = useIntl();
    const [, { addToast }] = useToasts();
    useDelayedTransition();

    const ERROR_MESSAGE = formatMessage({
        id: 'app.errorUnexpected',
        defaultMessage: 'Sorry! An unexpected error occurred.'
    });

    const handleIsOffline = useCallback(() => {
        addToast({
            type: 'error',
            icon: OfflineIcon,
            message: formatMessage({
                id: 'app.errorOffline',
                defaultMessage:
                    'You are offline. Some features may be unavailable.'
            }),
            timeout: 3000
        });
    }, [addToast, formatMessage]);

    const handleIsOnline = useCallback(() => {
        addToast({
            type: 'info',
            icon: OnlineIcon,
            message: formatMessage({
                id: 'app.infoOnline',
                defaultMessage: 'You are online.'
            }),
            timeout: 3000
        });
    }, [addToast, formatMessage]);

    const handleError = useCallback(
        (error, id, loc, handleDismissError) => {
            const errorToastProps = {
                icon: ErrorIcon,
                message: `${ERROR_MESSAGE}\nDebug: ${id} ${loc}`,
                onDismiss: remove => {
                    handleDismissError();
                    remove();
                },
                timeout: 15000,
                type: 'error'
            };

            addToast(errorToastProps);
        },
        [ERROR_MESSAGE, addToast]
    );

    const talonProps = useApp({
        handleError,
        handleIsOffline,
        handleIsOnline,
        markErrorHandled,
        renderError,
        unhandledErrors
    });

    const { hasOverlay, handleCloseDrawer } = talonProps;

    if (renderError) {
        return (
            <HeadProvider>
                <StoreTitle />
                <Main isMasked={true} />
                <Mask isActive={true} />
                <ToastContainer />
            </HeadProvider>
        );
    }

    return (
        <HeadProvider>
            <StoreTitle />
            <Main isMasked={hasOverlay}>
                <Routes />
            </Main>
            <Mask
                isActive={hasOverlay}
                dismiss={handleCloseDrawer}
                data-cy="App-Mask-button"
            />
            <Navigation />
            <ToastContainer />
        </HeadProvider>
    );
};

// App.propTypes = {
//     markErrorHandled: func.isRequired,
//     renderError: shape({
//         stack: string
//     }),
//     unhandledErrors: array
// };

App.globalCSS = globalCSS;

export default App;
