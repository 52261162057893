export const DesktopHeader = [
 
  {
    tabName: 'Refurbished Mobiles',
    href: 'productlisting',
    children: [
      {
        name: 'Apple',
        href: '/apple',
        sku: 'MDUD005600',
        subchildren: [
          {
            name: 'iphone 13',
            href: '/apple/iphone-13',
          },
          {
            name: 'iphone SE',
            href: '/apple/iphone-se',
          },
          {
            name: 'iphone 12 Pro',
            href: '/apple/iphone-12-pro',
          },
          {
            name: 'iPhone 5s',
            href: '/apple/iphone-5s',
          },
          {
            name: 'iPhone 5',
            href: '/apple/iphone-5',
          },
          {
            name: 'iPhone 5c',
            href: '/apple/iphone-5c',
          },
          {
            name: 'iPhone 6',
            href: '/apple/iphone-6',
          },
          {
            name: 'iPhone 6 Plus',
            href: '/apple/iphone-6-plus',
          },
          {
            name: 'iPhone 6S',
            href: '/apple/iphone-6s',
          },
          {
            name: 'iPhone 6S Plus',
            href: '/apple/iphone-6s-plus',
          },
          {
            name: 'iPhone SE 1st Generation',
            href: '/apple/iphone-se-1st-generation',
          },
          {
            name: 'iPhone 7',
            href: '/apple/iphone-7',
          },
          {
            name: 'iPhone 7 Plus',
            href: '/apple/iphone-7-plus',
          },
          {
            name: 'iPhone 8',
            href: '/apple/iphone-8',
          },
          {
            name: 'iPhone 8 Plus',
            href: '/apple/iphone-8-plus',
          },
          {
            name: 'iPhone X',
            href: '/apple/iphone-x',
          },
          {
            name: 'iPhone XR',
            href: '/apple/iphone-xr',
          },
          {
            name: 'iPhone XS',
            href: '/apple/iphone-xs',
          },
          {
            name: 'iPhone XS Max',
            href: '/apple/iphone-xs-max',
          },
          {
            name: 'iPhone 11',
            href: '/apple/iphone-11',
          },
          {
            name: 'iPhone 11 Pro',
            href: '/apple/iphone-11-pro',
          },
          {
            name: 'iPhone 11 Pro Max',
            href: '/apple/iphone-11-pro-max',
          },
          {
            name: 'iPhone SE 2020',
            href: '/apple/iphone-se-2020',
          },
          {
            name: 'iPhone 12',
            href: '/apple/iphone-12',
          },
          {
            name: 'iPhone 12 Mini',
            href: '/apple/iphone-12-mini',
          },
          {
            name: 'iPhone 12 Pro Max',
            href: '/apple/iphone-12-pro-max',
          },
          {
            name: 'iPhone 13 Mini',
            href: '/apple/iphone-13-mini',
          },
          {
            name: 'iPhone 13 Pro',
            href: '/apple/iphone-13-pro',
          },
          {
            name: 'iPhone 13 Pro Max',
            href: '/apple/iphone-13-pro-max',
          },
          {
            name: 'iPhone SE 2022',
            href: '/apple/iphone-se-2022',
          },
        ],
      },
      {
        name: 'Samsung',
        href: '/samsung',
        sku: 'MDUD006129-1',
        subchildren: [
          {
            name: 'Samsung Galaxy J7',
            href: '/samsung/j7-prime',
          },
          {
            name: 'Samsung Galaxy A03',
            href: '/samsung/samsung-galaxy-a03',
          },
          {
            name: 'Samsung Galaxy A10',
            href: '/samsung/samsung-galaxy-a10',
          },
          {
            name: 'Samsung Galaxy A10s',
            href: '/samsung/samsung-galaxy-a10s',
          },
          {
            name: 'Samsung Galaxy A12',
            href: '/samsung/samsung-galaxy-a12',
          },
          {
            name: 'Samsung Galaxy A13',
            href: '/samsung/samsung-galaxy-a13',
          },
          {
            name: 'Samsung Galaxy A2 Core',
            href: '/samsung/samsung-galaxy-a2-core',
          },
          {
            name: 'Samsung Galaxy A20s',
            href: '/samsung/samsung-galaxy-a20s',
          },
          {
            name: 'Samsung Galaxy A21s',
            href: '/samsung/samsung-galaxy-a21s',
          },
          {
            name: 'Samsung Galaxy A5',
            href: '/samsung/samsung-galaxy-a5',
          },
          {
            name: 'Samsung Galaxy A50',
            href: '/samsung/samsung-galaxy-a50',
          },
          {
            name: 'Samsung Galaxy A51',
            href: '/samsung/samsung-galaxy-a51',
          },
          {
            name: 'Samsung Galaxy A70s',
            href: '/samsung/samsung-galaxy-a70s',
          },
          {
            name: 'Samsung Galaxy A70',
            href: '/samsung/samsung-galaxy-a70',
          },
          {
            name: 'Samsung Galaxy A72',
            href: '/samsung/samsung-galaxy-a72',
          },
          {
            name: 'Samsung Galaxy C5',
            href: '/samsung/samsung-galaxy-c5',
          },
          {
            name: 'Samsung Galaxy C5 Pro',
            href: '/samsung/samsung-galaxy-c5-pro',
          },
          {
            name: 'Samsung Galaxy C9 Pro',
            href: '/samsung/samsung-galaxy-c9-pro',
          },
          {
            name: 'Samsung Galaxy E5',
            href: '/samsung/samsung-galaxy-e5',
          },
          {
            name: 'Samsung Galaxy F12',
            href: '/samsung/samsung-galaxy-f12',
          },
          {
            name: 'Samsung Galaxy F22',
            href: '/samsung/samsung-galaxy-f22',
          },
          {
            name: 'Samsung Galaxy F41',
            href: '/samsung/samsung-galaxy-f41',
          },
          {
            name: 'Samsung Galaxy F42 5G',
            href: '/samsung/samsung-galaxy-f42-5g',
          },
          {
            name: 'Samsung Galaxy Fold',
            href: '/samsung/samsung-galaxy-fold',
          },
          {
            name: 'Samsung Galaxy Grand 2',
            href: '/samsung/samsung-galaxy-grand-2',
          },
          {
            name: 'Samsung Galaxy J1 Ace',
            href: '/samsung/samsung-galaxy-j1-ace',
          },
          {
            name: 'Samsung Galaxy J5',
            href: '/samsung/samsung-galaxy-j5',
          },
          {
            name: 'Samsung Galaxy J7 Max',
            href: '/samsung/samsung-galaxy-j7-max',
          },
          {
            name: 'Samsung Galaxy M01s',
            href: '/samsung/samsung-galaxy-m01s',
          },
        ],
      },
      {
        name: 'OnePlus',
        href: '/oneplus',
        sku: 'MDUD004662-SC',
        subchildren: [
          {
            name: 'OnePlus 6T',
            href: '/oneplus/nord-2',
          },
          {
            name: 'OnePlus 6',
            href: '/oneplus/oneplus-9',
          },
          {
            name: 'OnePlus 5T',
            href: '/oneplus/oneplus-7',
          },
          {
            name: 'OnePlus 5',
            href: '/oneplus/oneplus-5',
          },
          {
            name: 'OnePlus 3T',
            href: '/oneplus/oneplus-3t',
          },
          {
            name: 'OnePlus 3',
            href: '/oneplus/oneplus-3',
          },
          {
            name: 'OnePlus X',
            href: '/oneplus/oneplus-x',
          },
          {
            name: 'OnePlus 2',
            href: '/oneplus/oneplus-2',
          },
          {
            name: 'OnePlus One',
            href: '/oneplus/oneplus-one',
          },
          {
            name: 'OnePlus 6T McLaren',
            href: '/oneplus/oneplus-6t-mclaren',
          },
          {
            name: 'OnePlus 7 Pro',
            href: '/oneplus/oneplus-7-pro',
          },
          {
            name: 'OnePlus 7T',
            href: '/oneplus/oneplus-7t',
          },
          {
            name: 'OnePlus 7T Pro',
            href: '/oneplus/oneplus-7t-pro',
          },
          {
            name: 'OnePlus 8',
            href: '/oneplus/oneplus-8',
          },
          {
            name: 'OnePlus 8 Pro',
            href: '/oneplus/oneplus-8-pro',
          },
          {
            name: 'OnePlus 7T Pro McLaren Edition',
            href: '/oneplus/oneplus-7t-pro-mclaren-edition',
          },
          {
            name: 'OnePlus Nord',
            href: '/oneplus/oneplus-nord',
          },
          {
            name: 'OnePlus 9 5G',
            href: '/oneplus/oneplus-9-5g',
          },
          {
            name: 'OnePlus 9R 5G',
            href: '/oneplus/oneplus-9r-5g',
          },
          {
            name: 'OnePlus 9 Pro 5G',
            href: '/oneplus/oneplus-9-pro-5g',
          },
          {
            name: 'OnePlus Nord CE 5G',
            href: '/oneplus/oneplus-nord-ce-5g',
          },
          {
            name: 'OnePlus Nord 2 5G',
            href: '/oneplus/oneplus-nord-2-5g',
          },
          {
            name: 'OnePlus 9RT 5G',
            href: '/oneplus/oneplus-9rt-5g',
          },
          {
            name: 'OnePlus Nord CE 2 5G',
            href: '/oneplus/oneplus-nord-ce-2-5g',
          },
          {
            name: 'OnePlus 10 Pro 5G',
            href: '/oneplus/oneplus-10-pro-5g',
          },
          {
            name: 'OnePlus Nord CE 2 Lite 5G',
            href: '/oneplus/oneplus-nord-ce-2-lite-5g',
          },
          {
            name: 'OnePlus 10R 5G',
            href: '/oneplus/oneplus-10r-5g',
          },
          {
            name: 'OnePlus Nord 2T 5G',
            href: '/oneplus/oneplus-nord-2t-5g',
          },
          {
            name: 'OnePlus 8T',
            href: '/oneplus/oneplus-8t',
          },
        ],
      },
      {
        name: 'Oppo',
        href: '/oppo',
        sku: 'MDUD007155',
        subchildren: [
          {
            name: 'OPPO A7',
            href: '/oppo/oppo-a7',
          },
          {
            name: 'OPPO F9 Pro',
            href: '/oppo/oppo-f9-pro',
          },
          {
            name: 'OPPO F9',
            href: '/oppo/oppo-f9',
          },
          {
            name: 'OPPO A3s',
            href: '/oppo/oppo-a3s',
          },
          {
            name: 'OPPO Find X',
            href: '/oppo/oppo-find-x',
          },
          {
            name: 'OPPO A5',
            href: '/oppo/oppo-a5',
          },
          {
            name: 'OPPO F7',
            href: '/oppo/oppo-f7',
          },
          {
            name: 'OPPO A83',
            href: '/oppo/oppo-a83',
          },
          {
            name: 'OPPO F5 Youth',
            href: '/oppo/oppo-f5-youth',
          },
          {
            name: 'OPPO F5',
            href: '/oppo/oppo-f5',
          },
          {
            name: 'OPPO A71',
            href: '/oppo/oppo-a71',
          },
          {
            name: 'OPPO A33',
            href: '/oppo/oppo-a33',
          },
          {
            name: 'OPPO R11',
            href: '/oppo/oppo-r11',
          },
          {
            name: 'OPPO R11 Plus',
            href: '/oppo/oppo-r11-plus',
          },
          {
            name: 'OPPO A77',
            href: '/oppo/oppo-a77',
          },
          {
            name: 'OPPO F3',
            href: '/oppo/oppo-f3',
          },
          {
            name: 'OPPO F3 Plus',
            href: '/oppo/oppo-f3-plus',
          },
          {
            name: 'OPPO A57',
            href: '/oppo/oppo-a57',
          },
          {
            name: 'OPPO F1s',
            href: '/oppo/oppo-f1s',
          },
          {
            name: 'OPPO A37',
            href: '/oppo/oppo-a37',
          },
          {
            name: 'OPPO F1 Plus',
            href: '/oppo/oppo-f1-plus',
          },
          {
            name: 'OPPO Neo 7',
            href: '/oppo/oppo-neo-7',
          },
          {
            name: 'OPPO R17 Pro',
            href: '/oppo/oppo-r17-pro',
          },
          {
            name: 'OPPO K1',
            href: '/oppo/oppo-k1',
          },
          {
            name: 'OPPO F11 Pro',
            href: '/oppo/oppo-f11-pro',
          },
          {
            name: 'OPPO A5s',
            href: '/oppo/oppo-a5s',
          },
          {
            name: 'OPPO F11',
            href: '/oppo/oppo-f11',
          },
          {
            name: 'OPPO Reno',
            href: '/oppo/oppo-reno',
          },
          {
            name: 'OPPO R15 Pro',
            href: '/oppo/oppo-r15-pro',
          },
          {
            name: 'OPPO K3',
            href: '/oppo/oppo-k3',
          },
        ],
      },
      {
        name: 'Vivo',
        href: '/vivo',
        sku: 'MDUD007449',
        subchildren: [
          {
            name: 'Vivo V9 Pro',
            href: '/vivo/vivo-v9-pro',
          },
          {
            name: 'Vivo V11 Pro',
            href: '/vivo/vivo-v11-pro',
          },
          {
            name: 'Vivo V11',
            href: '/vivo/vivo-v11',
          },
          {
            name: 'Vivo Y83 Pro',
            href: '/vivo/vivo-y83-pro',
          },
          {
            name: 'Vivo NEX',
            href: '/vivo/vivo-nex',
          },
          {
            name: 'Vivo Y71i',
            href: '/vivo/vivo-y71i',
          },
          {
            name: 'Vivo Y81',
            href: '/vivo/vivo-y81',
          },
          {
            name: 'Vivo Y83',
            href: '/vivo/vivo-y83',
          },
          {
            name: 'Vivo V9 Youth',
            href: '/vivo/vivo-v9-youth',
          },
          {
            name: 'Vivo Y71',
            href: '/vivo/vivo-y71',
          },
          {
            name: 'Vivo Y53i',
            href: '/vivo/vivo-y53i',
          },
          {
            name: 'Vivo X21',
            href: '/vivo/vivo-x21',
          },
          {
            name: '"Vivo V9',
            href: '/vivo/vivo-v9',
          },
          {
            name: 'Vivo V7',
            href: '/vivo/vivo-v7',
          },
          {
            name: 'Vivo V7 Plus',
            href: '/vivo/vivo-v7-plus',
          },
          {
            name: 'Vivo Y69',
            href: '/vivo/vivo-y69',
          },
          {
            name: 'Vivo X9',
            href: '/vivo/vivo-x9"',
          },
          {
            name: 'Vivo X9s',
            href: '/vivo/vivo-x9s',
          },
          {
            name: 'Vivo X9s Plus',
            href: '/vivo/vivo-x9s-plus',
          },
          {
            name: 'Vivo V5s',
            href: '/vivo/vivo-v5s',
          },
          {
            name: 'Vivo Y55s',
            href: '/vivo/vivo-y55s',
          },
          {
            name: 'Vivo Y66',
            href: '/vivo/vivo-y66',
          },
          {
            name: 'Vivo V5 Plus',
            href: '/vivo/vivo-v5-plus',
          },
          {
            name: 'Vivo V5',
            href: '/vivo/vivo-v5',
          },
          {
            name: 'Vivo Y53',
            href: '/vivo/vivo-y53',
          },
          {
            name: 'Vivo Y31L',
            href: '/vivo/vivo-y31l',
          },
          {
            name: 'Vivo Y21L',
            href: '/vivo/vivo-y21l',
          },
          {
            name: 'Vivo Y21',
            href: '/vivo/vivo-y21',
          },
          {
            name: 'Vivo V3',
            href: '/vivo/vivo-v3',
          },
          {
            name: 'Vivo V3 Max',
            href: '/vivo/vivo-v3-max',
          },
        ],
      },
      {
        name: 'Xiaomi',
        href: '/xiaomi',
        sku: 'Xiaomi Redmi Note 10 (Shadow Black, 6GB RAM, 128GB ROM) - 38810',
        subchildren: [
          {
            name: 'Xiaomi Redmi Note 6 Pro',
            href: '/xiaomi/xiaomi-redmi-note-6-pro',
          },
          {
            name: 'Xiaomi Mi A2',
            href: '/xiaomi/xiaomi-mi-a2',
          },
          {
            name: 'Xiaomi Redmi 6',
            href: '/xiaomi/xiaomi-redmi-6',
          },
          {
            name: 'Xiaomi Redmi 6 pro',
            href: '/xiaomi/xiaomi-redmi-6-pro',
          },
          {
            name: 'Xiaomi Redmi 6A',
            href: '/xiaomi/xiaomi-redmi-6a',
          },
          {
            name: 'Xiaomi Redmi Y2',
            href: '/xiaomi/xiaomi-redmi-y2',
          },
          {
            name: 'Xiaomi Redmi 5',
            href: '/xiaomi/xiaomi-redmi-5',
          },
          {
            name: 'Xiaomi Redmi Note 5 Pro',
            href: '/xiaomi/xiaomi-redmi-note-5-pro',
          },
          {
            name: 'Xiaomi Redmi Y1',
            href: '/xiaomi/xiaomi-redmi-y1',
          },
          {
            name: 'Mi Mix 2',
            href: '/xiaomi/mi-mix-2',
          },
          {
            name: 'Mi A1',
            href: '/xiaomi/mi-a1',
          },
          {
            name: 'Xiaomi Redmi Note 4',
            href: '/xiaomi/xiaomi-redmi-note-4',
          },
          {
            name: 'Redmi 3S Prime',
            href: '/xiaomi/redmi-3s-prime',
          },
          {
            name: 'Xiaomi Redmi 3S',
            href: '/xiaomi/xiaomi-redmi-3s',
          },
          {
            name: 'Xiaomi Mi Max',
            href: '/xiaomi/xiaomi-mi-max',
          },
          {
            name: 'Xiaomi Mi 5',
            href: '/xiaomi/xiaomi-mi-5',
          },
          {
            name: 'Redmi Note Prime',
            href: '/xiaomi/redmi-note-prime',
          },
          {
            name: 'Xiaomi Redmi 2 Prime',
            href: '/xiaomi/xiaomi-redmi-2-prime',
          },
          {
            name: 'Xiaomi Mi 4i',
            href: '/xiaomi/xiaomi-mi-4i',
          },
          {
            name: 'Xiaomi Redmi 2',
            href: '/xiaomi/xiaomi-redmi-2',
          },
          {
            name: 'Xiaomi Redmi Note 7 Pro',
            href: '/xiaomi/xiaomi-redmi-note-7-pro',
          },
          {
            name: 'Redmi 3',
            href: '/xiaomi/redmi-3',
          },
          {
            name: 'Xiaomi Redmi 7',
            href: '/xiaomi/xiaomi-redmi-7',
          },
          {
            name: 'Xiaomi Redmi Note 7S',
            href: '/xiaomi/xiaomi-redmi-note-7s',
          },
          {
            name: 'Xiaomi Black Shark 2',
            href: '/xiaomi/xiaomi-black-shark-2',
          },
          {
            name: 'Xiaomi Redmi K20',
            href: '/xiaomi/xiaomi-redmi-k20',
          },
          {
            name: 'Xiaomi Redmi 7A',
            href: '/xiaomi/xiaomi-redmi-7a',
          },
          {
            name: 'Xiaomi Redmi 8A',
            href: '/xiaomi/xiaomi-redmi-8a',
          },
          {
            name: 'Xiaomi Redmi Note 8',
            href: '/xiaomi/xiaomi-redmi-note-8',
          },
          {
            name: 'Xiaomi Redmi Note 9 Pro',
            href: '/xiaomi/xiaomi-redmi-note-9-pro',
          },
        ],
      },
      {
        name: 'Nothing',
        href: '/nothing',
      },
      {
        name: 'iQOO',
        href: '/iqoo',
      },
      {
        name: 'Realme',
        href: '/realme',
      },
      {
        name: 'Techno',
        href: '/techno',
      },
      // {
      //   name: 'Just Corseca',
      //   href: '/speakers',
      // },
    ],
  },
  {
    tabName: 'Refurbished Laptops',
    href: 'refurbished-laptops',
  },
  //{
   // tabName: 'Smart Watches',
  //  href: 'smartwatches',
  //},

  // start code khushali
 // {
  //  tabName: 'Audio Devices',
  //  href: 'audio-devices',
  //  children: [
   //   {
   //     name: 'Speakers',
   //     href: '/speakers',
      
   //   },
   //   {
   //     name: 'Headphones & Earphones',
   //     href: '/headphones-and-earphones',
   //   
  //    },
  //  ],
//  },
// end code
  
  // {
  //   tabName: 'Sell Your Phone',
  //   href: '',
  // },
 // {
  //  tabName: 'Stock Clearance',
  //  href: 'stock-clearance',
 // },
 
  
  

];
