import { useCallback, useMemo, useState } from 'react';

import mergeOperations from 'packages/framework/util/shallowMerge';
import DEFAULT_OPERATIONS from './createAccount.gql';
import BrowserPersistence from 'packages/framework/util/simplePersistence';
import { useApolloClient, useLazyQuery, useMutation } from '@apollo/client';
import { retrieveCartId } from 'packages/framework/store/actions/cart';
import { useCartContext, useCartState } from 'packages/framework/context/cart';
import { useUserContext, useUserState } from 'packages/framework/context/user';
import { useAwaitQuery } from 'packages/framework/hooks/useAwaitQuery';
import { useGoogleReCaptcha } from 'packages/framework/hooks/useGoogleReCaptcha';
import { useDispatchCompare } from 'packages/framework/context/compareContext';
import { compareActionTypes } from 'packages/framework/context/compare/compare.types';

/**
 * Returns props necessary to render CreateAccount component. In particular this
 * talon handles the submission flow by first doing a pre-submisson validation
 * and then, on success, invokes the `onSubmit` prop, which is usually the action.
 *
 * @param {CreateAccountQueries} props.queries queries used by the talon
 * @param {CreateAccountMutations} props.mutations mutations used by the talon
 * @param {InitialValues} props.initialValues initial values to sanitize and seed the form
 * @param {Function} props.onSubmit the post submit callback
 * @param {Function} props.onCancel the cancel callback
 *
 * @returns {CreateAccountProps}
 *
 * @example <caption>Importing into your project</caption>
 * import { useForgotPassword } from 'packages/framework/hooks/CreateAccount/useCreateAccount.js';
 */
export const useCreateAccount = props => {
  const storage = new BrowserPersistence();
  const { initialValues = {}, onSubmit, onCancel } = props;

  const operations = mergeOperations(DEFAULT_OPERATIONS, props.operations);
  const {
    createAccountMutation,
    createCartMutation,
    getCartDetailsQuery,
    getCustomerQuery,
    mergeCartsMutation,
    signInMutation,
    createAccountOtpMutation,
    createAccountOtpVerifyQuery,
    createCustomerAccountMutation,
    mergeCompareList,
  } = operations;

  const apolloClient = useApolloClient();
  const [isSubmitting, setIsSubmitting] = useState(false);
  // const [{ cartId }, { createCart, removeCart, getCartDetails }]: any = useCartContext();
  const { createCart, removeCart, getCartDetails }: any = useCartContext();
  const { cartId } = useCartState();
  // const [{ isGettingDetails }, { getUserDetails, setToken }]: any = useUserContext();
  const { getUserDetails, setToken }: any = useUserContext();
  const { isGettingDetails } = useUserState();
  const dispatch = useDispatchCompare();

  const [fetchCartId] = useMutation(createCartMutation);
  const [mergeCarts] = useMutation(mergeCartsMutation);
  const [mergeCompare] = useMutation(mergeCompareList);

  // For create account and sign in mutations, we don't want to cache any
  // personally identifiable information (PII). So we set fetchPolicy to 'no-cache'.

  const [
    createAccountOtp,
    { loading: createAccountOtpLoading, error: createAccountOtpError },
  ] = useMutation(createAccountOtpMutation, {
    fetchPolicy: 'no-cache',
  });

  const [
    createAccountVerify,
    {
      data: createAccountVerifyStatus,
      loading: verifyLoading,
      error: createAccountVerifyError,
    },
  ] = useLazyQuery(createAccountOtpVerifyQuery);

  const [
    createAccountCustomer,
    {
      loading: createAccountCustomerLoading,
      error: createAccountCustomerError,
    },
  ] = useMutation(createCustomerAccountMutation, {
    fetchPolicy: 'no-cache',
  });

  const [createAccount, { error: createAccountError }] = useMutation(
    createAccountMutation,
    {
      fetchPolicy: 'no-cache',
    },
  );

  const [signIn, { error: signInError }] = useMutation(signInMutation, {
    fetchPolicy: 'no-cache',
  });

  const fetchUserDetails = useAwaitQuery(getCustomerQuery);
  const fetchCartDetails = useAwaitQuery(getCartDetailsQuery);

  const { generateReCaptchaData, recaptchaLoading, recaptchaWidgetProps } =
    useGoogleReCaptcha({
      currentForm: 'CUSTOMER_CREATE',
      formAction: 'createAccount',
    });

  const handleCancel = useCallback(() => {
    onCancel();
  }, [onCancel]);

  const handleSubmit = useCallback(
    async formValues => {
      setIsSubmitting(true);
      try {
        // Get source cart id (guest cart id).
        const sourceCartId = cartId;

        // Get reCaptchaV3 Data for createAccount mutation
        const recaptchaDataForCreateAccount = await generateReCaptchaData();

        // Create the account and then sign in.
        await createAccount({
          variables: {
            email: formValues.email,
            firstname: formValues.firstname,
            lastname: formValues.lastname,
            password: formValues.password,
            is_subscribed: !!formValues.checkbox,
            otp: '',
            mobileNumber: `91${formValues.mobilenumber}`,
          },
          ...recaptchaDataForCreateAccount,
        });

        // Get reCaptchaV3 Data for signIn mutation
        // Get reCaptchaV3 Data for signIn mutation
        const recaptchaDataForSignIn = await generateReCaptchaData();

        const signInResponse = await signIn({
          variables: {
            email: formValues.email,
            password: formValues.password,
          },
          ...recaptchaDataForSignIn,
        });

        const token = signInResponse.data.generateCustomerToken.token;
        token && (await setToken(token));

        // Clear all cart/customer data from cache and redux.
        await apolloClient.clearCacheData(apolloClient, 'cart');
        await apolloClient.clearCacheData(apolloClient, 'customer');
        await removeCart();

        // Create and get the customer's cart id.
        await createCart({
          fetchCartId,
        });

        const destinationCartId = await retrieveCartId();

        // Merge the guest cart into the customer cart.
        // Merge the guest cart into the customer cart.
        await mergeCarts({
          variables: {
            destinationCartId,
            sourceCartId,
          },
        });

        // Assign guestUID if present, to user
        const uid = storage.getItem('compare_uid');
        if (uid) {
          await mergeCompare({
            variables: {
              uid,
            },
          }).then(result => {
            console.log(result);
            if (result.data.assignCompareListToCustomer?.result) {
              storage.setItem(
                'compare_list_count',
                result.data.assignCompareListToCustomer?.compare_list
                  ?.item_count,
              );
              storage.setItem(
                'compare_uid',
                result.data.assignCompareListToCustomer?.compare_list?.uid,
              );
              dispatch({
                type: compareActionTypes.INITIAL_STATE,
                payload: {
                  compare_count:
                    result.data.assignCompareListToCustomer?.compare_list
                      ?.item_count,
                  compare_items:
                    result.data.assignCompareListToCustomer?.compare_list,
                },
              });
            }
          });
        }

        // Ensure old stores are updated with any new data.
        await getUserDetails({ fetchUserDetails });
        await getCartDetails({
          fetchCartId,
          fetchCartDetails,
        });

        // Finally, invoke the post-submission callback.
        if (token && onSubmit) {
          onSubmit();
        }
      } catch (error) {
        if (process.env.NODE_ENV !== 'production') {
          console.error(error);
        }
        setIsSubmitting(false);
      }
    },
    [
      cartId,
      generateReCaptchaData,
      createAccount,
      signIn,
      setToken,
      apolloClient,
      removeCart,
      createCart,
      fetchCartId,
      mergeCarts,
      getUserDetails,
      fetchUserDetails,
      getCartDetails,
      fetchCartDetails,
      onSubmit,
    ],
  );

  const handleSubmitOTP = useCallback(
    async formValues => {
      try {
        const { data } = await createAccountOtp({
          variables: {
            mobileNumber: `91${formValues.mobilenumber}`,
          },
        });

        if (data?.createAccountOTP?.status) {
          return true;
        } else {
          alert(data?.createAccountOTP.message);
          // return false;
        }
      } catch (error) {
        console.log('error', error);
        alert(error);
      }
    },
    [
      cartId,
      generateReCaptchaData,
      setToken,
      apolloClient,
      createCart,
      fetchCartId,
      mergeCarts,
      getUserDetails,
      fetchUserDetails,
      getCartDetails,
      fetchCartDetails,
      onSubmit,
    ],
  );

  const handleVerifyOTP = useCallback(
    async (formValues, otp) => {
      try {
        const { data } = await createAccountVerify({
          variables: {
            mobileNumber: `91${formValues.mobilenumber}`,
            otp: otp,
          },
        });

        if (data?.createAccountOTPVerify?.status) {
          const sourceCartId = cartId;

          const { data } = await createAccountCustomer({
            variables: {
              mobileNumber: `91${formValues.mobilenumber}`,
              otp: otp,
              firstname: formValues.firstname,
              lastname: formValues.lastname,
              email: formValues.email,
            },
          });

          const token = data.createCustomerAccount.token;
          token && (await setToken(token));

          // Clear all cart/customer data from cache and redux.
          await apolloClient.clearCacheData(apolloClient, 'cart');
          await apolloClient.clearCacheData(apolloClient, 'customer');
          await removeCart();

          // Create and get the customer's cart id.
          await createCart({
            fetchCartId,
          });

          const destinationCartId = await retrieveCartId();

          // Merge the guest cart into the customer cart.
          // Merge the guest cart into the customer cart.
          await mergeCarts({
            variables: {
              destinationCartId,
              sourceCartId,
            },
          });

          // Assign guestUID if present, to user
          const uid = storage.getItem('compare_uid');
          if (uid) {
            await mergeCompare({
              variables: {
                uid,
              },
            }).then(result => {
              if (result.data.assignCompareListToCustomer?.result) {
                storage.setItem(
                  'compare_list_count',
                  result.data.assignCompareListToCustomer?.compare_list
                    ?.item_count,
                );
                storage.setItem(
                  'compare_uid',
                  result.data.assignCompareListToCustomer?.compare_list?.uid,
                );
                dispatch({
                  type: compareActionTypes.INITIAL_STATE,
                  payload: {
                    compare_count:
                      result.data.assignCompareListToCustomer?.compare_list
                        ?.item_count,
                    compare_items:
                      result.data.assignCompareListToCustomer?.compare_list,
                  },
                });
              }
            });
          }

          // Ensure old stores are updated with any new data.
          await getUserDetails({ fetchUserDetails });
          await getCartDetails({
            fetchCartId,
            fetchCartDetails,
          });

          if (token && onSubmit) {
            onSubmit();
          }

          // return data;
        } else {
          alert(data?.createAccountOTPVerify?.message);
          console.log('error', data);
        }
      } catch (error) {
        console.log('error', error);
        alert(error);
      }
    },
    [
      cartId,
      generateReCaptchaData,
      setToken,
      apolloClient,
      createCart,
      fetchCartId,
      mergeCarts,
      getUserDetails,
      fetchUserDetails,
      getCartDetails,
      fetchCartDetails,
      onSubmit,
    ],
  );

  const sanitizedInitialValues = useMemo(() => {
    const { email, firstName, lastName, ...rest } = initialValues;

    return {
      customer: { email, firstname: firstName, lastname: lastName },
      ...rest,
    };
  }, [initialValues]);

  const errors = useMemo(
    () =>
      new Map([
        ['createAccountQuery', createAccountError],
        ['signInMutation', signInError],
        ['createAccountOtpMutation', createAccountOtpError],
        ['createAccountOtpVerifyQuery', createAccountVerifyError],
        ['createCustomerAccountMutation', createAccountCustomerError],
      ]),
    [
      createAccountError,
      signInError,
      createAccountCustomerError,
      createAccountVerifyError,
      createAccountOtpError,
    ],
  );

  return {
    errors,
    handleCancel,
    handleSubmit,
    initialValues: sanitizedInitialValues,
    isDisabled: isSubmitting || isGettingDetails, // || recaptchaLoading,
    createAccountOtpLoading,
    isLoading: createAccountCustomerLoading || verifyLoading,
    // recaptchaWidgetProps,
    handleSubmitOTP,
    handleVerifyOTP,
  };
};

/** JSDocs type definitions */

/**
 * GraphQL queries for the create account form.
 * This is a type used by the {@link useCreateAccount} talon.
 *
 * @typedef {Object} CreateAccountQueries
 *
 * @property {GraphQLAST} customerQuery query to fetch customer details
 * @property {GraphQLAST} getCartDetailsQuery query to get cart details
 */

/**
 * GraphQL mutations for the create account form.
 * This is a type used by the {@link useCreateAccount} talon.
 *
 * @typedef {Object} CreateAccountMutations
 *
 * @property {GraphQLAST} createAccountMutation mutation for creating new account
 * @property {GraphQLAST} createCartMutation mutation for creating new cart
 * @property {GraphQLAST} mergeCartsMutation mutation for merging carts
 * @property {GraphQLAST} signInMutation mutation for signing
 */

/**
 * Initial values for the create account form.
 * This is a type used by the {@link useCreateAccount} talon.
 *
 * @typedef {Object} InitialValues
 *
 * @property {String} email email id of the user
 * @property {String} firstName first name of the user
 * @property {String} lastName last name of the user
 */

/**
 * Sanitized initial values for the create account form.
 * This is a type used by the {@link useCreateAccount} talon.
 *
 * @typedef {Object} SanitizedInitialValues
 *
 * @property {String} email email id of the user
 * @property {String} firstname first name of the user
 * @property {String} lastname last name of the user
 */

/**
 * Object type returned by the {@link useCreateAccount} talon.
 * It provides props data to use when rendering the create account form component.
 *
 * @typedef {Object} CreateAccountProps
 *
 * @property {Map} errors a map of errors to their respective mutations
 * @property {Function} handleCancel callback function to handle form cancellations
 * @property {Function} handleSubmitCreateAccount callback function to handle form submission
 * @property {SanitizedInitialValues} initialValues initial values for the create account form
 * @property {Boolean} isDisabled true if either details are being fetched or form is being submitted. False otherwise.
 * @property {Object} recaptchaWidgetProps - Props for the GoogleReCaptcha component.
 * @property {Function} recaptchaWidgetProps.containerElement - Container reference callback.
 * @property {Boolean} recaptchaWidgetProps.shouldRender - Checks if component should be rendered.
 */
