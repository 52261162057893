import { bool, shape, string } from 'prop-types';
import defaultClasses from './socialAuthentication.scss';
import { FormattedMessage } from 'react-intl';
import SocialButtons from '../SocialButtons/socialButtons';
import { useSocialLoginContext } from 'packages/framework/context/socialLogin';
import { useUserState } from 'packages/framework/context/user';
import { mergeClasses } from 'packages/framework/util/classify';

const SocialAuthentication = props => {
  const { mode, isPopup, showCreateAccount } = props;
  const { buttons, isEnabled, buttonPosition, enabledModes } =
    useSocialLoginContext();

  const { isSignedIn } = useUserState();

  if (!isEnabled || !enabledModes.includes(mode) || isSignedIn) {
    return null;
  }

  const classes = mergeClasses(defaultClasses, props.classes);

  const separator =
    mode === 'popup' ? (
      <div className={classes.separator}>
        <span className={classes.separatorIcon}>
          <FormattedMessage
            id={'amSocialLogin.separator'}
            defaultMessage={'or'}
          />
        </span>
      </div>
    ) : null;

  const rootClass = isPopup
    ? `${classes.root_popup} ${classes[buttonPosition]}`
    : `${classes.root} ${classes[mode]}`;

  return (
    <div className={rootClass}>
      {/* {separator} */}
      {/* <div className={classes.title}>
        <FormattedMessage
          id={'amSocialLogin.titleText'}
          defaultMessage={'Login with Your Social Profile'}
        />
      </div> */}
      <SocialButtons
        buttons={buttons}
        showCreateAccount={showCreateAccount}
        mode={mode}
      />
    </div>
  );
};

SocialAuthentication.propTypes = {
  classes: shape({
    root: string,
  }),
  mode: string,
  isPopup: bool,
};

export default SocialAuthentication;
