import { useCallback, useRef, useState, useMemo } from 'react';
import mergeOperations from 'packages/framework/util/shallowMerge';
import { useCartContext, useCartState } from 'packages/framework/context/cart';
import { useUserContext, useUserState } from 'packages/framework/context/user';
import DEFAULT_OPERATIONS from './signIn.gql';
import BrowserPersistence from 'packages/framework/util/simplePersistence';
import { useApolloClient, useLazyQuery, useMutation } from '@apollo/client';
import { retrieveCartId } from 'packages/framework/store/actions/cart';
import { useAwaitQuery } from 'packages/framework/hooks/useAwaitQuery';
import { useGoogleReCaptcha } from 'packages/framework/hooks/useGoogleReCaptcha';
import { compareActionTypes } from 'packages/framework/context/compare/compare.types';
import { useDispatchCompare } from 'packages/framework/context/compareContext';
/**
 * Returns props necessary to render signIn component. In particular this
 * talon handles the submission flow by first doing a pre-submisson validation
 * and then, on success, invokes the `onSubmit` prop, which is usually the action.
 *
 * @param {Function} props.onSubmit the post submit callback
 *
 *
 */

const storage = new BrowserPersistence();

export const useSignIn = props => {
  const {
    getCartDetailsQuery,
    setDefaultUsername,
    showCreateAccount,
    showForgotPassword,
    onSubmit,
  } = props;

  const operations = mergeOperations(DEFAULT_OPERATIONS, props.operations);
  const {
    createCartMutation,
    getCustomerQuery,
    mergeCartsMutation,
    signInMutation,
    loginOtpMutation,
    loginOtpVerifyMutation,
    mergeCompareList,
  } = operations;

  const apolloClient = useApolloClient();
  const [isSigningIn, setIsSigningIn] = useState(false);
  const dispatch = useDispatchCompare();

  const { createCart, removeCart, getCartDetails }: any = useCartContext();
  const { cartId } = useCartState();

  const { getUserDetails, setToken }: any = useUserContext();
  const { isGettingDetails, getDetailsError } = useUserState();

  const [
    createLogintOtp,
    { loading: createLoginOtpLoading, error: createLoginOtpError },
  ] = useMutation(loginOtpMutation, {
    fetchPolicy: 'no-cache',
  });

  const [
    createLoginOtpVerify,
    {
      data: createLoginOtpVerifyStatus,
      loading: createLoginOtpVerifyLoading,
      error: createLoginOtpVerifyError,
    },
  ] = useLazyQuery(loginOtpVerifyMutation);

  const [signIn, { error: signInError }] = useMutation(signInMutation, {
    fetchPolicy: 'no-cache',
  });

  const { generateReCaptchaData, recaptchaLoading, recaptchaWidgetProps } =
    useGoogleReCaptcha({
      currentForm: 'CUSTOMER_LOGIN',
      formAction: 'signIn',
    });

  const [fetchCartId] = useMutation(createCartMutation);
  const [mergeCarts] = useMutation(mergeCartsMutation);
  const [mergeCompare] = useMutation(mergeCompareList);
  const fetchUserDetails = useAwaitQuery(getCustomerQuery);
  const fetchCartDetails = useAwaitQuery(getCartDetailsQuery);

  const handleSubmit = useCallback(
    async ({ email, password }) => {
      setIsSigningIn(true);
      try {
        // Get source cart id (guest cart id).
        const sourceCartId = cartId;

        // Get recaptchaV3 data for login
        // const recaptchaData = await generateReCaptchaData();

        // Sign in and set the token.
        const signInResponse = await signIn({
          variables: {
            email,
            password,
          },
          // ...recaptchaData,
        });

        // Clear all cart/customer data from cache and redux.
        await apolloClient.clearCacheData(apolloClient, 'cart');
        await apolloClient.clearCacheData(apolloClient, 'customer');
        await removeCart();

        const token = signInResponse.data.generateCustomerToken.token;
        await setToken(token);

        // Create and get the customer's cart id.
        await createCart({
          fetchCartId,
        });
        const destinationCartId = await retrieveCartId();

        // Merge the guest cart into the customer cart.
        await mergeCarts({
          variables: {
            destinationCartId,
            sourceCartId,
          },
        });

        // Assign guestUID if present, to user
        const uid = storage.getItem('compare_uid');
        if (uid) {
          await mergeCompare({
            variables: {
              uid,
            },
          })
            .then(result => {
              if (result?.data?.assignCompareListToCustomer?.result) {
                storage.setItem(
                  'compare_list_count',
                  result.data.assignCompareListToCustomer?.compare_list
                    ?.item_count,
                );
                storage.setItem(
                  'compare_uid',
                  result.data.assignCompareListToCustomer?.compare_list?.uid,
                );
                dispatch({
                  type: compareActionTypes.INITIAL_STATE,
                  payload: {
                    compare_count:
                      result.data.assignCompareListToCustomer?.compare_list
                        ?.item_count,
                    compare_items:
                      result.data.assignCompareListToCustomer?.compare_list,
                  },
                });
              }
            })
            .catch(e => {
              console.error('Compare list error =========================>>>>>>>>>', e);
            });
        }

        // Ensure old stores are updated with any new data.
        getUserDetails({ fetchUserDetails });
        getCartDetails({ fetchCartId, fetchCartDetails });
        // Finally, invoke the post-submission callback.
        if (token && onSubmit) {
          onSubmit();
        }
      } catch (error) {
        if (process.env.NODE_ENV !== 'production') {
          console.error(error);
        }
      } finally {
        setIsSigningIn(false)
      }
    },
    [
      cartId,
      generateReCaptchaData,
      signIn,
      setToken,
      apolloClient,
      removeCart,
      createCart,
      fetchCartId,
      mergeCarts,
      getUserDetails,
      fetchUserDetails,
      getCartDetails,
      fetchCartDetails,
    ],
  );

  const handleSubmitOTP = useCallback(
    async formValues => {
      try {
        const { data } = await createLogintOtp({
          variables: {
            mobileNumber: `91${formValues.mobilenumber}`,
          },
        });

        if (data?.loginOTP?.status) {
          return true;
        } else {
          alert(data?.loginOTP.message);
          // return false;
        }
      } catch (error) {
        console.log('error', error);
        alert(error);
      }
    },
    [
      cartId,
      generateReCaptchaData,
      setToken,
      apolloClient,
      createCart,
      fetchCartId,
      mergeCarts,
      getUserDetails,
      fetchUserDetails,
      getCartDetails,
      fetchCartDetails,
      onSubmit,
      createLogintOtp,
      createLoginOtpVerify,
    ],
  );

  const handleVerifyOTP = useCallback(
    async formValues => {
      setIsSigningIn(true);
      try {
        const { data } = await createLoginOtpVerify({
          variables: {
            mobileNumber: `91${formValues.mobilenumber}`,
            otp: formValues.otp,
          },
        });

        if (data?.loginOTPVerify.status) {
          // Get source cart id (guest cart id).
          const sourceCartId = cartId;

          const token = data?.loginOTPVerify?.token;
          await setToken(token);

          // Clear all cart/customer data from cache and redux.
          await apolloClient.clearCacheData(apolloClient, 'cart');
          await apolloClient.clearCacheData(apolloClient, 'customer');
          await removeCart();

          // Create and get the customer's cart id.
          await createCart({
            fetchCartId,
          });

          const destinationCartId = await retrieveCartId();
          // Merge the guest cart into the customer cart.
          await mergeCarts({
            variables: {
              destinationCartId,
              sourceCartId,
            },
          });

          // Assign guestUID if present, to user
          const uid = storage.getItem('compare_uid');
          if (uid) {
            await mergeCompare({
              variables: {
                uid,
              },
            }).then(result => {
              if (result.data.assignCompareListToCustomer?.result) {
                storage.setItem(
                  'compare_list_count',
                  result.data.assignCompareListToCustomer?.compare_list
                    ?.item_count,
                );
                storage.setItem(
                  'compare_uid',
                  result.data.assignCompareListToCustomer?.compare_list?.uid,
                );
                dispatch({
                  type: compareActionTypes.INITIAL_STATE,
                  payload: {
                    compare_count:
                      result.data.assignCompareListToCustomer?.compare_list
                        ?.item_count,
                    compare_items:
                      result.data.assignCompareListToCustomer?.compare_list,
                  },
                });
              }
            });
          }

          // Ensure old stores are updated with any new data.

          getUserDetails({ fetchUserDetails });
          getCartDetails({ fetchCartId, fetchCartDetails });
          // Finally, invoke the post-submission callback.
          if (token && onSubmit) {
            onSubmit();
          }
        } else {
          alert(data?.loginOTPVerify.message);
          console.log(data);
        }
      } catch (error) {
        console.log('error', error);
        alert(error);
      } finally {
        setIsSigningIn(false);
      }
    },
    [
      cartId,
      generateReCaptchaData,
      setToken,
      apolloClient,
      createCart,
      fetchCartId,
      mergeCarts,
      getUserDetails,
      fetchUserDetails,
      getCartDetails,
      fetchCartDetails,
      onSubmit,
      createLogintOtp,
      createLoginOtpVerify,
    ],
  );

  const handleForgotPassword = useCallback(() => {
    // const { current: formApi } = formApiRef;

    // if (formApi) {
    //   setDefaultUsername(formApi.getValue("email"));
    // }

    showForgotPassword();
  }, [setDefaultUsername, showForgotPassword]);

  const handleCreateAccount = useCallback(() => {
    // const { current: formApi } = formApiRef;

    // if (formApi) {
    //   setDefaultUsername(formApi.getValue("email"));
    // }

    showCreateAccount();
  }, [setDefaultUsername, showCreateAccount]);

  const errors = useMemo(
    () =>
      new Map([
        ['getUserDetailsQuery', getDetailsError],
        ['signInMutation', signInError],
        ['loginOtpVerifyMutation', createLoginOtpVerifyError],
        ['loginOtpMutation', createLoginOtpError],
      ]),
    [
      getDetailsError,
      signInError,
      createLoginOtpError,
      createLoginOtpVerifyError,
    ],
  );

  return {
    errors,
    handleCreateAccount,
    handleForgotPassword,
    handleSubmit,
    isBusy: isGettingDetails || isSigningIn,
    recaptchaLoading,
    recaptchaWidgetProps,
    createLoginOtpLoading,
    createLoginOtpVerifyLoading,
    handleSubmitOTP,
    handleVerifyOTP,
  };
};
