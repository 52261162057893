import React, { lazy, Suspense, useState } from 'react';
import {
  Routes as RouterRoutes,
  useLocation,
  useRoutes,
} from 'react-router-dom';
import { fullPageLoadingIndicator } from '../LoadingIndicator';
import MagentoRoute from '../MagentoRoute';
import { useScrollTopOnChange } from 'packages/framework/hooks/useScrollTopOnChange';
import { createPageRoutes } from 'packages/util/createPageRoutes';

const Routes = () => {
  const { pathname } = useLocation();
  useScrollTopOnChange(pathname);

  const [pages] = useState(() => {
    const routes: any = import.meta.glob('/src/routes/**/*.server.[jt]sx');
    const pages: any[] = createPageRoutes(routes, '/').map(
      ({ path, componentLoader, exact }) => {
        let Lazy = lazy(componentLoader);
        return {
          path,
          caseSensitive: false,
          element: (
            <Suspense fallback={null}>
              <Lazy />
            </Suspense>
          ),
        };
      },
    );

    pages.push({
      path: '*',
      caseSensitive: false,
      element: <MagentoRoute />,
    });
    return pages;
  });
  const routes = useRoutes(pages);

  return (
    <Suspense fallback={fullPageLoadingIndicator}>
      {routes}
      {/* <RouterRoutes> */}
      {/*
       * Client-side routes are injected by BabelRouteInjectionPlugin here.
       * Venia's are defined in packages/venia-ui/lib/targets/venia-ui-intercept.js
       */}
      {/* <Route index element={<HomePage />} /> */}
      {/* <Route path="*" element={<MagentoRoute />} /> */}
      {/* </RouterRoutes> */}
      {/* <Route path="/productlisting" element={<ProductListing />} />
        <Route path="/product" element={<ProductFullDetail />} /> */}
    </Suspense>
  );
};

export default Routes;
const availableRoutes = [];
export { availableRoutes };
