import React, { Fragment, Suspense, useState } from 'react';
import { useIntl } from 'react-intl';

import HeaderIcon from './headerIcon';
import { Link, useNavigate } from 'react-router-dom';
import { useWindowSize } from 'packages/framework/hooks/useWindowSize';
import { Accordian } from '../Accordian';
import Price from 'packages/framework/components/Price';
import { GET_ITEM_COUNT_QUERY } from 'src/hooks/Header/cartTrigger.gql';
import { useCartTrigger } from 'src/hooks/Header/useCartTrigger';

const MiniCart = React.lazy(() => import('../MiniCart'));

const CartTrigger = props => {
  const {
    handleLinkClick,
    handleTriggerClick,
    subTotal,
    itemCount,
    miniCartRef,
    miniCartIsOpen,
    hideCartTrigger,
    setMiniCartIsOpen,
    miniCartTriggerRef,
  } = useCartTrigger({
    queries: {
      getItemCountQuery: GET_ITEM_COUNT_QUERY,
    },
  });

  const { isDesktop, isTablet, isMobile } = useWindowSize();
  const [selectedIndex, setSelectedIndex] = useState(null);

  const navigate = useNavigate();

  //   const classes = useStyle(defaultClasses, props.classes);
  const { formatMessage } = useIntl();
  const buttonAriaLabel = formatMessage(
    {
      id: 'cartTrigger.ariaLabel',
      defaultMessage: 'Toggle mini cart. You have {count} items in your cart.',
    },
    { count: itemCount },
  );
  const itemCountDisplay = itemCount > 99 ? '99+' : itemCount;
  //   const triggerClassName = miniCartIsOpen
  //     ? classes.triggerContainer_open
  //     : classes.triggerContainer;

  //   const maybeItemCounter = itemCount ? (
  //     <span className={classes.counter} data-cy="CartTrigger-counter">
  //       {itemCountDisplay}
  //     </span>
  //   ) : null;

  const cartTrigger = hideCartTrigger ? null : (
    // Because this button behaves differently on desktop and mobile
    // we render two buttons that differ only in their click handler
    // and control which one displays via CSS.
    <Fragment>
      <HeaderIcon
        id="cartIcon"
        className="group relative"
        w="35px"
        h="34px"
        x="-124px"
        y="-16px"
      >

     {/* Code for Cart plus item show in mobile view*/}
       
         
          <>
            <Link to="#" className="cart-font-bold">
             {itemCountDisplay}{' '}
            
            </Link>
         
          </>
        
     

        {/*End*/}
        <div className="group-hover:block absolute top-full right-[-30px] hidden  w-[300px] sm:w-[320px] text-[13px]  h-auto nav-items bg-white z-[100] shadow-md border border-solid border-[#ddd]">
          <Suspense>
            <MiniCart isOpen={miniCartIsOpen} setIsOpen={setMiniCartIsOpen} />
          </Suspense>
        </div>
      </HeaderIcon>
    </Fragment>
  );

  return cartTrigger;
};

export default CartTrigger;

// CartTrigger.propTypes = {
//     classes: shape({
//         counter: string,
//         link: string,
//         openIndicator: string,
//         root: string,
//         trigger: string,
//         triggerContainer: string
//     })
// };
