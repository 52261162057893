import { shape, string, func } from 'prop-types';
import defaultClasses from './socialButtons.scss';
import { FormattedMessage } from 'react-intl';
import { mergeClasses } from 'packages/framework/util/classify';

const Button = props => {
  const { label, type, url, handleLogin, buttonShape } = props;
  const classes = mergeClasses(defaultClasses, props.classes);
  const isRectangleShape = buttonShape === 'rectangle';

  const btnText = isRectangleShape ? (
    <span className={`${classes.text} text-sm`}>
      <FormattedMessage
        id={'amSocialLogin.continueWith'}
        defaultMessage={'Continue with {label}'}
        values={{ label }}
      />
    </span>
  ) : null;

  const style = {
    '--amslBtnBg': `var(--amsl-${type}-button__${
      isRectangleShape ? 'rectangle__' : ''
    }background)`,
  };

  return (
    <button
      // style={style}
      type="button"
      title={`Sign in with ${label}`}
      className={`${classes.button} ${classes[type]} w-full rounded flex justify-center items-center text-base text-brandBlack mx-auto bg-white p-2`}
      onClick={() => handleLogin({ type, url })}
    >
      {type == 'google' && (
        <img className="mr-2" src="/icons/google.png" width="24" />
      )}
      {type == 'facebook' && (
        <img className="mr-2" src="/icons/facebook.png" width="24" />
      )}
      {/* <SocialIcon type={type} /> */}
      {btnText}
    </button>
  );
};

Button.propTypes = {
  classes: shape({
    root: string,
  }),
  label: string,
  type: string,
  url: string,
  buttonShape: string,
  handleLogin: func,
};

export default Button;
