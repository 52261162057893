import classes from './headerIcon.module.scss';
import clsx from 'clsx';
const HeaderIcon = props => {
  const style = {
    '--background-image': props.background,
    '--header-icon-width': props.w,
    '--header-icon-height': props.h,
    '--header-icon-x-pos': props.x,
    '--header-icon-y-pos': props.y,
  };
  return (
    <div
      id={props?.id}
      className={clsx(classes.root, props.className)}
      style={style}
      onClick={props.onClick}
    >
        {props.children}
    </div>
  );
};
export default HeaderIcon;
