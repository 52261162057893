import { createRoot, hydrateRoot } from 'react-dom/client';
import { EntryContext } from 'packages/framework/route/entry';
import BrowserPersistence from 'packages/framework/util/simplePersistence';
import createStore from './store';
import './index.scss';
import Adapter from 'packages/framework/components/Adapter';

const storage = new BrowserPersistence();

declare global {
  var __remixContext: EntryContext;
  var __remixManifest: EntryContext['manifest'];
}

const styles = new Set();
const isServer = !globalThis.document;
const origin = isServer
  ? import.meta.env.VITE_MAGENTO_BACKEND_URL
  : globalThis.location.origin;

const MainApp = props => {
  // let historyRef = useRef<BrowserHistory>();
  // if (historyRef.current == null) {
  //   historyRef.current = createBrowserHistory({ window });
  // }

  // let history = historyRef.current;
  // let [state, dispatch] = useReducer((_: Update, update: Update) => update, {
  //   action: history.action,
  //   location: history.location,
  // });

  // useLayoutEffect(() => history.listen(dispatch), [history]);

  // // let entryContext = window.__remixContext;
  // let entryContext: any = {};
  // // entryContext.manifest = window.__remixManifest;
  // // entryContext.routeModules = window.__remixRouteModules;
  // // In the browser, we don't need this because a) in the case of loader
  // // errors we already know the order and b) in the case of render errors
  // // React knows the order and handles error boundaries normally.
  // // entryContext.appState.trackBoundaries = false;
  // // entryContext.appState.trackCatchBoundaries = false;

  // const [routes] = useState(() => {
  // const routes: any = import.meta.glob('./routes/**/*.server.[jt]sx');
  //   const pages: any[] = createPageRoutes(routes, '/').map(
  //     ({ path, componentLoader, exact }) => {
  //       let Lazy = lazy(componentLoader);
  //       return {
  //         path,
  //         caseSensitive: false,
  //         element: (
  //           <Suspense fallback={null}>
  //             <Lazy />
  //           </Suspense>
  //         ),
  //       };
  //     },
  //   );

  //   pages.push({
  //     path: '*',
  //     caseSensitive: false,
  //     element: <MagentoRoute />,
  //   });
  //   return pages;
  // });

  return (
    <Adapter origin={origin} styles={styles} store={createStore} />
    //   {/* <AppEntry
    //     routes={routes}
    //     context={entryContext}
    //     action={state.action}
    //     location={state.location}
    //     navigator={history}
    //   /> */}
    // {/* </Adapter> */}
  );
};
// {/* </UseGraphQLProvider> */}

const root = document.getElementById('root');

if (root?.hasChildNodes()) {
  hydrateRoot(root, <MainApp />);
} else {
  let rootA;
  if (!rootA) {
    rootA = createRoot(root);
  }
  rootA.render(<MainApp />);
}
