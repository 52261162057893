import Price from 'packages/framework/components/Price';
import { useWindowSize } from 'packages/framework/hooks/useWindowSize';
import { ReactElement, useMemo, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Rating from '../Rating';
import { DesktopHeader } from './DesktopHeader';
import { ButtonCartIcon } from 'src/components/Button-CartIcon';
import { ButtonWishlistIcon } from 'src/components/Button-WishlistIcon';
import { ButtonCompareIcon } from 'src/components/Button-CompareIcon';
import { ButtonViewIcon } from 'src/components/Button-ViewIcon';
import ModalComponent from 'src/components/Modal';
import { CartModal } from 'src/components/Modal-Components/CartModal';
import { CartSuccessModal } from 'src/components/Modal-Components/CartSuccessModal';
import { CompareModal } from 'src/components/Modal-Components/CompareModal';
import { WishlistModal } from 'src/components/Modal-Components/WiishlistModal';
import { ViewModal } from 'src/components/Modal-Components/ViewModal';
import { useGalleryItem } from 'packages/framework/hooks/Gallery/useGalleryItem';
import React from 'react';
import { useHeaderItems } from 'src/hooks/Header/useHeaderItems';
import { useIsInViewport } from 'packages/framework/hooks/useIsInViewport';
import Shimmer from 'packages/framework/components/Shimmer';
const WishlistButton = React.lazy(
  () => import('components/Wishlist/AddToListButton'),
);

const HeaderBottom = props => {
  const { isDesktop } = useWindowSize();
  const [activeHeaderTab, setActiveHeaderTab] = useState(
    window.sessionStorage.getItem('activeTab') || 'Home',
  );

  return (
    <>
      <div className="grid grid-cols-10 bg-brandWhite ">
        {/* <div className="col-span-2 flex justify-center">
          {isDesktop && (
            <div className="location-box flex flex-row items-center justify-center ml-4 pb-2">
              <a className="location-icon mr-2"></a>
              <div className="flex flex-col leading-3">
                <span className="deliver-to-text text-[white]">Deliver to</span>
                <span className="location-text text-[white] font-bold">
                  India
                </span>
              </div>
            </div>
          )}
        </div> */}

        <div className="col-span-10 flex flex-row justify-center">
          {isDesktop &&
            DesktopHeader.map(i => (
              <HeaderTab
                key={`header_tab_${i.tabName}`}
                href={i.href}
                children={i.children}
                tabName={i.tabName}
                isDropdown={i.children ? true : false}
                activeHeaderTab={activeHeaderTab}
                setActiveHeaderTab={setActiveHeaderTab}
              />
            ))}
        </div>
      </div>
    </>
  );
};

interface HeaderTextObject {
  children?: ReactElement | String;
  className?: String;
}

// const HeaderText = ({ children, className }: HeaderTextObject) => {
//   return (
//     <span className={`${className} top-header-text text-center`}>
//       {children}
//     </span>
//   );
// };

interface HeaderTabObject {
  tabName: string;
  href: String;
  children?: any;
  isDropdown?: Boolean;
  isActive?: Boolean;
  activeHeaderTab?: String;
  setActiveHeaderTab?: Function;
}

const HeaderTab = ({
  tabName,
  href,
  children,
  isDropdown,
  activeHeaderTab,
  setActiveHeaderTab,
}: HeaderTabObject) => {
  const { isDesktop } = useWindowSize();
  const headerSubcategoryLimit = 15;
  const getActiveHeader=()=>{
    if(( window.location.pathname.includes(`/${href}`) && href && href !="productlisting" ) ||
      (window.location.pathname.split("/")[1].length == 0 && tabName == "Home") ||
      ( window.location.pathname.includes(`/${href}`) && href == "productlisting" &&  activeHeaderTab==tabName )){
       return true;
    }
    else {
      return false;
    }
  }
  return (
    <div className="group relative">
      <Link
        to={`/${href}`}
        onClick={() => {
          window.sessionStorage.setItem('activeTab', tabName);
          setActiveHeaderTab(tabName);
        }}
      >
        <span
          className={`${
            isDropdown ? 'header-tab' : ''
          } flex items-center justify-center ml-2 mr-2 mt-2 pb-2 text-sm hover:text-orange
        ${
          getActiveHeader()
            ? 'font-bold text-orange'
            : 'text-brandBlack'
        }
       
         `}
        >
          {tabName}
        </span>
      </Link>
      {/* // ${item.subchildren ? '' : ''} */}
      {children ? (
        <ul className="z-[2] group-hover:block absolute top-full hidden min-h-[446px] h-auto nav-items bg-white  w-32 shadow-md border border-solid border-[#ddd]">
          {children.map(item => (
            <div key={`child_menu_item_${item.name}`} className="child-item">
              <Link
                to={`${item.href}`}
                className={`flex flex-wrap justify-between py-2 px-4 text-brandBlack  block hover:text-orange`}
              >
                <p className={`text-sm font-normal leading-[24px]  `}>
                  {item.name}
                </p>
              </Link>
              {/* {item?.subchildren ? (
                <div className="sub-child-list absolute hidden min-h-[446px] h-auto p-4 pt-2 text-[13px] nav-items bg-white left-full top-[-1px] z-[1000] lg:w-[700px] xl:w-[650px] shadow-md border border-solid border-[#ddd]">
                  <div className="flex flex-wrap">
                    <ul className="w-[30%] ">
                      {item?.subchildren.slice(0, 15).map((item, i) => (
                        <div key={`header-subcategory${i}`}>
                          <Link to={`${item.href}`} className="py-[3px] block ">
                            <p className=" font-normal text-brandBlack leading-[22px] hover:text-orange ">
                              {item.name}
                            </p>
                          </Link>
                        </div>
                      ))}
                    </ul>
                    <ul className="w-[30%] ">
                      {item?.subchildren?.length > headerSubcategoryLimit ? (
                        <div className="border-l border-solid border-[#ddd] pl-4">
                          {item?.subchildren.slice(15, 30).map((item, i) => (
                            <div key={`header-subcategory-two${i}`}>
                              <Link
                                to={`${item.href}`}
                                className="py-[3px] block "
                              >
                                <p className=" font-normal text-brandBlack leading-[22px] hover:text-orange ">
                                  {item.name}
                                </p>
                              </Link>
                            </div>
                          ))}
                        </div>
                      ) : null}
                    </ul>
                    <HeaderItem sku={item?.sku} />
                  </div>
                </div>
              ) : null} */}
            </div>
          ))}
        </ul>
      ) : null}
    </div>
  );
};

export default HeaderBottom;

// const HeaderItem = props => {
//   const { sku } = props;
//   const ref = useRef(null);
//   const shouldRender = useIsInViewport({
//     elementRef: ref,
//   });
//   const talonProps = useHeaderItems({ sku, shouldRender });
//   const { headerItems } = talonProps;

//   return (
//     <div
//       className="w-[40%] border-l border-solid border-[#ddd] header-item"
//       ref={ref}
//     >
//       {headerItems?.products?.items[0] ? (
//         <HeaderProduct item={headerItems?.products?.items[0]} />
//       ) : (
//         <Shimmer width={'100%'} height={'100%'} />
//       )}
//     </div>
//   );
// };

// const HeaderProduct = props => {
//   const { item } = props;
//   const {
//     handleLinkClick,
//     //item,
//     wishlistButtonProps,
//     isSupportedProductType,
//     handleAddtoCompareList,
//   }: any = useGalleryItem(props);

//   const productDetails = {
//     product: {
//       name: item?.name,
//     },
//   };
//   const [prodDetails, setProdDetails] = useState(null);
//   const [prodIndex, setprodIndex] = useState(0);
//   const { isDesktop, isTablet, isMobile } = useWindowSize();

//   const [isCartOpen, toggleIsCartOpen] = useState(false);
//   const showHideCart = isCartOpen ? ' display-block' : 'hide-modal';
//   const closeCartModal = () => {
//     toggleIsCartOpen(!isCartOpen);
//   };

//   const [isCartSuccessOpen, toggleIsCartSuccessOpen] = useState(false);
//   const showHideCartSuccess = isCartSuccessOpen
//     ? ' display-block'
//     : 'hide-modal';
//   const closeCartSuccessModal = () => {
//     toggleIsCartSuccessOpen(!isCartSuccessOpen);
//   };

//   const [isCompareOpen, toggleIsCompareOpen] = useState(false);
//   const showHideCompare = isCompareOpen ? ' display-block' : 'hide-modal';
//   const closeCompareModal = () => {
//     toggleIsCompareOpen(!isCompareOpen);
//   };

//   const [isWishlistOpen, toggleIsWishlistOpen] = useState(false);
//   const showHideWishlist = isWishlistOpen ? ' display-block' : 'hide-modal';
//   const closeWishlistModal = () => {
//     toggleIsWishlistOpen(!isWishlistOpen);
//   };

//   const [isViewOpen, toggleIsViewOpen] = useState(false);
//   const showHideView = isViewOpen ? ' display-block' : 'hide-modal';
//   const closeViewModal = () => {
//     toggleIsViewOpen(!isViewOpen);
//   };

//   return (
//     <>
//       <div className=" w-full p-4 pb-0">
//         <div className="header-image-container border-b border-solid border-[#ddd] w-[150px] h-auto pb-[5px] m-auto">
//           <Link
//             // onClick={handleLinkClick}
//             to={`/${item?.url_key}.html`}
//             className="flex h-auto items-center justify-center"
//           >
//             <img
//               className="object-contain h-[240px] w-auto"
//               alt={item?.name}
//               src={item?.small_image?.url}
//             />
//           </Link>
//           <div className="overlay">
//             <ButtonCartIcon
//               toggleIsOpen={toggleIsCartOpen}
//               setIndex={setprodIndex}
//               setCurrentModalProd={setProdDetails}
//               productList={item}
//               index={0}
//             />
//             <ButtonWishlistIcon
//               toggleIsOpen={toggleIsWishlistOpen}
//               setIndex={setprodIndex}
//               index={0}
//             >
//               <WishlistButton
//                 className="categoryWishlist"
//                 toggleButton={toggleIsWishlistOpen}
//                 {...{
//                   item: {
//                     sku: item?.sku,
//                     quantity: 1,
//                   },
//                   // storeConfig,
//                 }}
//               />
//             </ButtonWishlistIcon>
//             <ButtonCompareIcon
//               toggleIsOpen={toggleIsCompareOpen}
//               setIndex={setprodIndex}
//               index={0}
//               productList={item}
//               handleAddtoCompareList={handleAddtoCompareList}
//             />
//             {isDesktop ? (
//               <ButtonViewIcon
//                 toggleIsOpen={toggleIsViewOpen}
//                 setIndex={setprodIndex}
//                 setCurrentModalProd={setProdDetails}
//                 productList={item}
//                 index={0}
//               />
//             ) : null}
//           </div>
//         </div>

//         <div className="pt-[10px] text-center relative item-info">
//           <Link
//             //onClick={handleLinkClick}
//             to={`/${item?.url_key}.html`}
//             className="py-[6px] font-medium leading-[1.3] text-sm "
//             data-cy="GalleryItem-name"
//           >
//             <span>{item?.name}</span>
//           </Link>

//           <div className="font-medium text-sm ">
//             <span className=" text-orange">
//               <Price
//                 value={item?.price_range.maximum_price.final_price?.value}
//                 currencyCode={
//                   item?.price_range.maximum_price.final_price?.currency
//                 }
//               />
//             </span>
//             {item?.price_range?.maximum_price?.discount?.percent_off > 0 ? (
//               <>
//                 <div className="flex flex-wrap justify-center text-xs">
//                   <span className=" ml-2 text-[#707070] line-through">
//                     <Price
//                       value={
//                         item?.price_range?.maximum_price?.regular_price?.value
//                       }
//                       currencyCode={
//                         item?.price_range?.maximum_price?.regular_price
//                           ?.currency
//                       }
//                     />
//                   </span>

//                   <span className=" ml-1 text-[#707070]">
//                     {'('}
//                     {item?.price_range?.maximum_price?.discount?.percent_off}
//                     {'%'}&nbsp;{'off)'}
//                   </span>
//                 </div>
//               </>
//             ) : null}
//           </div>
//           <div className="text-sm review-stars">
//             {/* <AverageReview /> */}
//             <Rating rating={item?.rating_summary || 0} />
//           </div>
//         </div>
//       </div>

//       {isCartOpen && (
//         <ModalComponent showHideClassName={showHideCart} close={closeCartModal}>
//           <CartModal
//             product={item}
//             close={closeCartModal}
//             setIndex={setprodIndex}
//             toggleIsOpen={toggleIsCartSuccessOpen}
//             index={prodIndex}
//             toggleIsWishlistOpen={toggleIsWishlistOpen}
//             toggleIsCompareOpen={toggleIsCompareOpen}
//           />
//         </ModalComponent>
//       )}
//       {isCartSuccessOpen && (
//         <ModalComponent
//           showHideClassName={showHideCartSuccess}
//           close={closeCartSuccessModal}
//         >
//           <CartSuccessModal product={item} close={closeCartSuccessModal} />
//         </ModalComponent>
//       )}
//       {isCompareOpen && (
//         <ModalComponent
//           showHideClassName={showHideCompare}
//           close={closeCompareModal}
//         >
//           <CompareModal product={item} close={closeCompareModal} />
//         </ModalComponent>
//       )}

//       {isWishlistOpen && (
//         <ModalComponent
//           showHideClassName={showHideWishlist}
//           close={closeWishlistModal}
//         >
//           <WishlistModal product={productDetails} close={closeWishlistModal} />
//         </ModalComponent>
//       )}

//       {isViewOpen && (
//         <ModalComponent
//           className={'viewmodal'}
//           showHideClassName={showHideView}
//           close={closeViewModal}
//         >
//           <ViewModal
//             product={item}
//             close={closeViewModal}
//             toggleIsOpen={toggleIsCartSuccessOpen}
//             toggleIsWishlistOpen={toggleIsWishlistOpen}
//             toggleIsCompareOpen={toggleIsCompareOpen}
//           />
//         </ModalComponent>
//       )}
//     </>
//   );
// };
