import './header.css';
import HeaderMiddle from './headerMiddle';

// import HeaderTop from './headerTop';
// import SideMenu from 'components/SideMenu';
import './header.scss';
//import HomePageModal1 from 'src/components/HomePage/HomePageModal1'
//const currentURL = window.location.href;
//console.log(new URL(document.URL));

//console.log(currentURL);


// Code For UTM URL SPLIT START 
const currentURL = window.location.href;
import { lazy, Suspense, useEffect, useState } from 'react';
import BrowserPersistence from 'packages/framework/util/simplePersistence';
const storage = new BrowserPersistence();
//import { lazy, Suspense, useEffect, useState } from 'react';


const HomepageModal = lazy(
  () => import('src/components/HomePage/HomePageModal'),
);

const HomepageModalView = props => {
const [modal, setModal] = useState(null);
  useEffect(() => {
    let timeout,
      hpm = storage.getItem('hpm');

    timeout =
      hpm == undefined &&
      setTimeout(() => {
        if (!window['isPreRendering']) {
          setModal(true);
          storage.setItem('hpm', '1', 86400);
        }
      }, 3000);
    return () => {
      timeout && clearTimeout(timeout);
    };
  }, []);

  return modal ? (
    <>
      <HomepageModal />
    </>
  ) : null;
};


const queryParameters = new URLSearchParams(window.location.search);
const utm_source = queryParameters.get("utm_source");
const utm_campaign = queryParameters.get("utm_campaign");
const utm_medium = queryParameters.get("utm_medium");
const utm_term = queryParameters.get("utm_term");
const existing_utm_source = Cookies.get('utm_source');
const existing_utm_campaign = Cookies.get('utm_campaign');
const existing_utm_medium = Cookies.get('utm_medium');
const existing_utm_term = Cookies.get('utm_term');

if(utm_source || utm_campaign || utm_medium || utm_term){

    /* SET UTM SOURCE */
    if(existing_utm_source == utm_source)
    {
        
    }
    else{
        
      Cookies.set('utm_source', utm_source , {path: "/", expires: 5});
    }

    /* SET UTM CAMPAIGN */

    if(existing_utm_campaign == utm_campaign){

    }else{
       Cookies.set('utm_campaign', utm_campaign , {path: "/", expires: 5});
    }

    /* SET UTM MEDIUM */

    if(existing_utm_medium == utm_medium){

    }else{
      Cookies.set('utm_medium', utm_medium , {path: "/", expires: 5});
    }

    /* SET UTM NAME */

    if(existing_utm_term == utm_term){

    }else{
      Cookies.set('utm_term', utm_term , {path: "/", expires: 5});
    }
}else{

  
  if(existing_utm_source || existing_utm_campaign || existing_utm_medium || existing_utm_term){
   /* console.log("INN EXISTING parameter");
    console.log(existing_utm_source);
    console.log(existing_utm_campaign);
    console.log(existing_utm_medium);
    console.log(existing_utm_term);
*/
     Cookies.set('utm_source', existing_utm_source , {path: "/", expires: 5});
    Cookies.set('utm_campaign', existing_utm_campaign , {path: "/", expires: 5});
     Cookies.set('utm_medium', existing_utm_medium , {path: "/", expires: 5});
    Cookies.set('utm_term', existing_utm_term , {path: "/", expires: 5});
  }
  else{
    Cookies.set('utm_source', utm_source , {path: "/", expires: 5});
    Cookies.set('utm_campaign', utm_campaign , {path: "/", expires: 5});
    Cookies.set('utm_medium', utm_medium , {path: "/", expires: 5});
    Cookies.set('utm_term', utm_term , {path: "/", expires: 5});
  }
}

//UTM URL SPLIT END


const Header = () => {
  const [isSideMenu, setSideMenu] = useState(false);

  return (
    <>
      {/* <SideMenu isSideMenu={isSideMenu} setSideMenu={setSideMenu} open={open} /> */}
    
{/*code by khushali*/}
      <div className="header-container headercss">
        {/* <HeaderTop /> */}

        <HeaderMiddle />
      <HomepageModalView />
      </div>
      {/*end*/}
    </>
  );
};

export default Header;

//cashkaro header code added by Deepak @30-11-22.

function cid() {
  var e;
  var s1;
  var s2;
  return Promise.resolve().then(function () {
    e = new URLSearchParams(window.location.search).get("pid");
    s1 = new URLSearchParams(window.location.search).get("sub1");
    s2 = new URLSearchParams(window.location.search).get("sub2");
    return null === e ? null : fetch("https://secure.traqkarr.com/click?pid=" + e + "&sub1=" + s1 +"&sub2=" + s2 +"&offer_id=18&format=json").then(function (e) {
      return e.json();
    });
  });
}

function sc(e) {
  var c = new Date();
  c.setTime(c.getTime() + 365 * 24 * 60 * 60 * 1000), document.cookie = "affclick=" + e + ";expires=" + c.toUTCString() + ";path=/" + ";domain=.mobex.in", localStorage.setItem("affclick", e);
}

(function () {
  var e;
  return Promise.resolve().then(function () {
    return cid();
  }).then(function (_resp) {
    e = _resp;
    null !== e && sc(e.clickid);
  });
})();