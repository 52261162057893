import { gql } from '@apollo/client';
import { CheckoutPageFragment } from 'packages/framework/hooks/CheckoutPage/checkoutPageFragments.gql';

export const CREATE_ACCOUNT_OTP = gql`
  mutation CreateAccountOTP($mobileNumber: String!) {
    createAccountOTP(mobileNumber: $mobileNumber, token: "Mobex@123") {
      status
      message
    }
  }
`;

export const CREATE_ACCOUNT_OTP_VERIFY = gql`
  query CreateAccountOTPVerify($mobileNumber: String!, $otp: String!) {
    createAccountOTPVerify(mobileNumber: $mobileNumber, otp: $otp) {
      status
      message
    }
  }
`;

export const CREATE_CUSTOMER_ACCOUNT = gql`
  mutation createCustomerAccount(
    $mobileNumber: String!
    $otp: String!
    $firstname: String!
    $lastname: String!
    $email: String!
  ) {
    createCustomerAccount(
      mobileNumber: $mobileNumber
      otp: $otp
      input: { firstname: $firstname, lastname: $lastname, email: $email }
    ) {
      customer {
        firstname
      }
      token
      status
      message
    }
  }
`;

// Commenting this default graphql as we need mobilenumber in the registration
// export const CREATE_ACCOUNT = gql`
//   mutation CreateAccount(
//     $email: String!
//     $firstname: String!
//     $lastname: String!
//     $password: String!
//     $is_subscribed: Boolean!
//   ) {
//     createCustomer(
//       input: {
//         email: $email
//         firstname: $firstname
//         lastname: $lastname
//         password: $password
//         is_subscribed: $is_subscribed
//       }
//     ) {
//       # The createCustomer mutation returns a non-nullable CustomerOutput type
//       # which requires that at least one of the sub fields be returned.

//       # eslint-disable-next-line @graphql-eslint/require-id-when-available
//       customer {
//         email
//       }
//     }
//   }
// `;

export const CREATE_ACCOUNT = gql`
  mutation CreateCustomerAccount(
    $mobileNumber: String!
    $otp: String
    $firstname: String!
    $lastname: String!
    $email: String!
    $password: String!
    $is_subscribed: Boolean!
  ) {
    createCustomerAccount(
      mobileNumber: $mobileNumber
      otp: $otp
      input: {
        firstname: $firstname
        lastname: $lastname
        email: $email
        password: $password
        is_subscribed: $is_subscribed
      }
    ) {
      customer {
        firstname
      }
      token
      status
      message
    }
  }
`;

export const GET_CUSTOMER = gql`
  query GetCustomerAfterCreate {
    # eslint-disable-next-line @graphql-eslint/require-id-when-available
    customer {
      email
      firstname
      lastname
      is_subscribed
    }
  }
`;

export const SIGN_IN = gql`
  mutation SignInAfterCreate($email: String!, $password: String!) {
    generateCustomerToken(email: $email, password: $password) {
      token
    }
  }
`;

export const CREATE_CART = gql`
  mutation CreateCartAfterAccountCreation {
    cartId: createEmptyCart
  }
`;

export const GET_CART_DETAILS = gql`
  query GetCartDetailsAfterAccountCreation($cartId: String!) {
    cart(cart_id: $cartId) {
      id
      # eslint-disable-next-line @graphql-eslint/require-id-when-available
      items {
        uid
        prices {
          price {
            value
          }
        }
        # eslint-disable-next-line @graphql-eslint/require-id-when-available
        product {
          uid
          name
          sku
          small_image {
            url
            label
          }
          price {
            regularPrice {
              amount {
                value
              }
            }
          }
        }
        quantity
        # eslint-disable-next-line @graphql-eslint/require-id-when-available
        ... on ConfigurableCartItem {
          # eslint-disable-next-line @graphql-eslint/require-id-when-available
          configurable_options {
            configurable_product_option_uid
            option_label
            configurable_product_option_value_uid
            value_label
          }
        }
      }
      prices {
        grand_total {
          value
          currency
        }
      }
    }
  }
`;

export const MERGE_CARTS = gql`
  mutation MergeCartsAfterAccountCreation(
    $sourceCartId: String!
    $destinationCartId: String!
  ) {
    mergeCarts(
      source_cart_id: $sourceCartId
      destination_cart_id: $destinationCartId
    ) {
      id
      # eslint-disable-next-line @graphql-eslint/require-id-when-available
      items {
        uid
      }
      ...CheckoutPageFragment
    }
  }
  ${CheckoutPageFragment}
`;

export const MERGE_COMPARE_LIST = gql`
  mutation AssignCompareListToCustomer($uid: ID!) {
    assignCompareListToCustomer(uid: $uid) {
      result
      compare_list {
        uid
        item_count
        attributes {
          code
          label
        }
        items {
          uid
          product {
            id
            uid
            sku
            name
            url_key
            stock_status
            rating_summary
            review_count
            media_gallery_entries {
              uid
              label
              position
              disabled
              file
            }
            media_gallery {
              url
              label
              disabled
              position
            }
            __typename
            image {
              url
            }
            small_image {
              url
            }
            description {
              html
            }
            short_description {
              html
            }
            manufacturer
            price_range {
              maximum_price {
                final_price {
                  currency
                  value
                }
                fixed_product_taxes {
                  amount {
                    currency
                    value
                  }
                  label
                }
                discount {
                  percent_off
                  amount_off
                }
                regular_price {
                  currency
                  value
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default {
  createAccountMutation: CREATE_ACCOUNT,
  createCartMutation: CREATE_CART,
  getCartDetailsQuery: GET_CART_DETAILS,
  getCustomerQuery: GET_CUSTOMER,
  mergeCartsMutation: MERGE_CARTS,
  signInMutation: SIGN_IN,
  createAccountOtpMutation: CREATE_ACCOUNT_OTP,
  createAccountOtpVerifyQuery: CREATE_ACCOUNT_OTP_VERIFY,
  createCustomerAccountMutation: CREATE_CUSTOMER_ACCOUNT,
  mergeCompareList: MERGE_COMPARE_LIST,
};
