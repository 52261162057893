import { gql } from '@apollo/client';

export const CheckoutPageFragment = gql`
  fragment CheckoutPageFragment on Cart {
    id
    email
    # eslint-disable-next-line @graphql-eslint/require-id-when-available
    items {
      uid
      # eslint-disable-next-line @graphql-eslint/require-id-when-available
      product {
        uid
        stock_status
        prepaid_only
        colour
            price_range {
      maximum_price {
        final_price {
          currency
          value
        }
        fixed_product_taxes {
          amount {
            currency
            value
          }
          label
        }
        discount {
          percent_off
          amount_off
        }
        regular_price {
          currency
          value
        }
      }
      minimum_price {
        final_price {
          currency
          value
        }
      }
    }
          custom_attributes {
      selected_attribute_options {
        attribute_option {
          uid
          label
          is_default
        }
      }
      entered_attribute_value {
        value
      }
      attribute_metadata {
        uid
        code
        label
        attribute_labels {
          store_code
          label
        }
        data_type
        is_system
        entity_type
        ui_input {
          ui_input_type
          is_html_allowed
        }
        ... on ProductAttributeMetadata {
          used_in_components
        }
      }
    }
         ... on CustomizableProductInterface {
                    options {
                      title
                      required
                      ... on CustomizableDropDownOption {
                        dropDown: value {
                          title
                          price
                          uid
                          price_type
                        }
                      }
                    }
                  }
        name
        thumbnail {
          url
        }
        sku
        categories {
          uid
          name
        }
      }
      prices {
        price {
          value
          currency
        }
        row_total_including_tax {
          value
          currency
        }
      }
      quantity
    }
    prices {
      grand_total {
        value
        currency
      }
      subtotal_including_tax {
        value
        currency
      }
      applied_taxes {
        amount {
          currency
          value
        }
        label
      }
      discounts {
        label
        amount {
          currency
          value
        }
      }
    }
    # If total quantity is falsy we render empty.
    total_quantity
    available_payment_methods {
      code
    }
    applied_coupons {
      code
    }
    selected_payment_method {
      code
    }
    billing_address {
      firstname
      lastname
      telephone
      street
      postcode
      city
    }
    shipping_addresses {
      available_shipping_methods {
        carrier_code
        carrier_title
        method_code
        method_title
        amount {
          currency
          value
        }
        available
      }
      firstname
      lastname
      telephone
      street
      postcode
      city
      region {
        code
        label
        region_id
      }
      country {
        code
      }
    }
  }
`;
