import React from 'react';
import { shape, string, array } from 'prop-types';
import defaultClasses from './socialButtons.scss';
import { useSocButtons } from 'src/hooks/SocialAuth/useSocButtons';
import { mergeClasses } from 'packages/framework/util/classify';
import Button from './button';

const SocialButtons = props => {
  const { buttons, mode } = props;
  const {
    buttonShape,
    visibleButtons,
    handleLogin,
    isShowMoreBtn,
    handleShowMore,
    errors,
    errorRef,
  } = useSocButtons({ ...props });

  if (!Array.isArray(buttons) || !buttons.length) {
    return null;
  }
  const classes = mergeClasses(defaultClasses, props.classes);

  const list = visibleButtons.map(btn => (
    <Button
      key={btn.type}
      buttonShape={buttonShape}
      {...btn}
      handleLogin={handleLogin}
    />
  ));

  // const showMoreBtn = isShowMoreBtn ? (
  //   <button className={classes.showMore} onClick={handleShowMore}>
  //     <FormattedMessage
  //       id={'amSocialLogin.showMore'}
  //       defaultMessage={'Show More'}
  //     />
  //   </button>
  // ) : null;

  const errorMessage =
    errors && errors.message ? (
      <span
        ref={errorRef}
        className={`${classes.message} block pt-2.5 text-center text-red-500 text-sm `}
      >
        {errors.message}
      </span>
    ) : null;

  return (
    <div
      className={`ayush ${classes.root} ${classes[buttonShape]} ${classes[mode]}`}
    >
      <div className={`${classes.list} flex flex-col gap-4`}>{list}</div>
      {/* {showMoreBtn} */}
      {errorMessage}
    </div>
  );
};

SocialButtons.propTypes = {
  buttons: array,
  classes: shape({
    root: string,
  }),
};

export default SocialButtons;
