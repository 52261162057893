import React, { createContext, useContext, useState } from 'react';
import { useConfigs } from 'src/talons/useConfigs';

const AmXsearchContext = createContext(null);
const { Provider } = AmXsearchContext;

const AmXsearchProvider = props => {
  const { children } = props;

  const [singleProduct, setSingleProduct] = useState({ enabled: false });

  const talonProps = useConfigs();
  const { error } = talonProps;

  if (error) {
    if (process.env.NODE_ENV !== 'production') {
      console.error(error);
    }

    console.log('Check if Amasty modules has been installed!');
  }

  const contextValue = {
    ...talonProps,
    singleProduct,
    setSingleProduct
  };

  return  <Provider value={contextValue} > {children} </Provider>;
};

export default AmXsearchProvider;

export const useAmXsearchContext = () => useContext(AmXsearchContext);
