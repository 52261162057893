import React, { useContext, useReducer, createContext } from 'react';
import { userReducer } from './compare/compare.reducer';
import { initialState } from './compare/compare.state';

const CompareStateContext = createContext(null);
const CompareDispatchContext = createContext(null);

const CompareContextProvider = ({ children }) => {
  const [state, dispatchState] = useReducer(userReducer, initialState);

  return (
    <CompareDispatchContext.Provider value={dispatchState}>
      <CompareStateContext.Provider value={state}>
        {children}
      </CompareStateContext.Provider>
    </CompareDispatchContext.Provider>
  );
};

export const useCompareState = () => useContext(CompareStateContext);
export const useDispatchCompare = () => useContext(CompareDispatchContext);

export default CompareContextProvider;
