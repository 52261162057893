import { lazy } from 'react';

const PRODUCT = lazy(() => import('RootComponents/Product'));
const CATEGORY = lazy(() => import('RootComponents/Category'));
const CMS_PAGE = lazy(() => import('RootComponents/CMS'));
const NOT_FOUND = lazy(() => import('RootComponents/NotFound'));
// const SEARCH = () => import("packages/framework/RootComponents/CMS");
const componentMap = {
  PRODUCT,
  CATEGORY,
  CMS_PAGE,
  NOT_FOUND,
};
export default function getRootComponent(type) {
  return componentMap[type] || componentMap.NOT_FOUND;
}
