import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useMagentoRoute } from 'packages/framework/hooks/MagentoRoute';
import ErrorView from 'packages/framework/components/ErrorView';
import RootShimmerComponent from '../../RootComponents/Shimmer';
import { Helmet } from 'react-helmet-async';

const MESSAGES = new Map()
    .set(
        'NOT_FOUND',
        "Looks like the page you were hoping to find doesn't exist. Sorry about that."
    )
    .set('INTERNAL_ERROR', 'Something went wrong. Sorry about that.');

const MagentoRoute = () => {
    const { formatMessage } = useIntl();
    const talonProps = useMagentoRoute();

    const {
        component: RootComponent,
        isLoading,
        isNotFound,
        isRedirect,
        shimmer,
        initial,
        relativeUrl,
        ...componentData
    } = talonProps;
    if (isLoading || isRedirect) {
        // Show root component shimmer
        if (shimmer) {
            return <>
                {isRedirect ? (
                <Helmet>
                    <meta name="prerender-status-code" content="301" />
                    <meta
                    name="prerender-header"
                    content={`Location: ${SECURE_BASE_URL}${relativeUrl}`}
                    />
                </Helmet>
                ) : null}
                <RootShimmerComponent type={shimmer} />;
            </>
        }

        return initial ? null : <RootShimmerComponent />;
    } else if (RootComponent) {
        return <RootComponent {...componentData} />;
    } else if (isNotFound) {
        return (<>
            <Helmet>
                <meta name="prerender-status-code" content="404" />
            </Helmet>
            <ErrorView
                message={formatMessage({
                    id: 'magentoRoute.routeError',
                    defaultMessage: MESSAGES.get('NOT_FOUND')
                })}
                />
        </>
        );
    }

    return (
        <ErrorView
            message={formatMessage({
                id: 'magentoRoute.internalError',
                defaultMessage: MESSAGES.get('INTERNAL_ERROR')
            })}
        />
    );
};

export default MagentoRoute;
