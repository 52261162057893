import { useCallback, useRef, useMemo, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import mergeOperations from 'packages/framework/util/shallowMerge';
import DEFAULT_OPERATIONS from './newsletter.gql';
import { useUserState } from 'packages/framework/context/user';
import { subscribe } from 'graphql';
import { set } from 'react-hook-form';
import { useToasts } from 'packages/framework/Toasts';

export const useNewsletter = (props = {}) => {
    const [, { addToast }] = useToasts();
    const { subscribeMutation, getStoreConfigQuery, updatenewsletter, GetCustomerDetails } = mergeOperations(
        DEFAULT_OPERATIONS,
        props.operations
    );

    const [loaderLoading, setloaderLoading] = useState(false);

    const { data: getcust } = useQuery(GetCustomerDetails , {
        fetchPolicy: 'no-cache',
        onCompleted(data) {
            setChecked(data.customer.is_subscribed)
            setloaderLoading(false);
        },
      });

    const [checked, setChecked] = useState(null)
    const handleClick = () => setChecked(!checked)

    const formApiRef = useRef(null);
    const [newsLetterError, setNewsLetterError] = useState(null);
    const clearErrors = () => setNewsLetterError(null);

    const [
        subscribeNewsLetter,
        { data, loading: subscribeLoading }
    ] = useMutation(updatenewsletter, {
        fetchPolicy: 'no-cache',
        onError: setNewsLetterError
    });

    const { data: storeConfigData, loading: configLoading } = useQuery(
        getStoreConfigQuery,
        {
            fetchPolicy: 'cache-and-network'
        }
    );

    const isEnabled = useMemo(() => {
        return !!storeConfigData?.storeConfig?.newsletter_enabled;
    }, [storeConfigData]);

    const setFormApi = useCallback(api => (formApiRef.current = api), []);

       
    const handleSubmit = useCallback(
        async () => {
            setloaderLoading(true);
            try {
                await subscribeNewsLetter({
                    variables: { subscribed:checked }
                }).then(()=>{
                    if(checked){
                        addToast({
                            type: 'success',
                            message: 'Subscribed to Newsletter',
                            dismissable: true,
                            timeout: 3000,
                            })
                    }
                    else{
                        addToast({
                            type: 'success',
                            message: 'UnSubscribed to Newsletter',
                            dismissable: true,
                            timeout: 3000,
                            })
                    }
                })
                setloaderLoading(false);
                if (formApiRef.current) {
                    formApiRef.current.reset();
                }
            } catch (error) {
                if (process.env.NODE_ENV !== 'production') {
                    console.error(error);
                }
                setloaderLoading(false);
            }
        },
        [subscribeNewsLetter ,checked, setChecked, handleClick]
    );

    const errors = useMemo(
        () => new Map([['subscribeMutation', newsLetterError]]),
        [newsLetterError]
    );
    const [
         addNewsLetter,
        { loading: newsletterLoading }
    ] = useMutation(subscribeMutation, {
        fetchPolicy: 'no-cache',
        onError: setNewsLetterError
    });
    const addNewsletterEmail = useCallback(
         (email) => {
            try {
                 addNewsLetter({
                    variables: {email }
                }).then(({data,errors})=>{
                    if(errors){
                        addToast({
                            type: 'failure',
                            message: `${errors}`,
                            dismissable: true,
                            timeout: 3000,
                            }) 
                    }
                    else{
                    addToast({
                      type: 'success',
                      message: 'Subscribed to Newsletter',
                      dismissable: true,
                      timeout: 3000,
                      });}
                    }
            )
            
               
            } catch (error) {
                if (process.env.NODE_ENV !== 'production') {
                    console.error(error);
                }
            }
        },
        []
    );


    return {
        loaderLoading,
        setloaderLoading,
        isEnabled,
        errors,
        handleSubmit,
        handleClick,
        checked,
        isBusy: subscribeLoading,
        isLoading: configLoading,
        setFormApi,
        clearErrors,
        addNewsletterEmail
    };
};
