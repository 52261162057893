import React, { useCallback } from 'react';
import { ChevronDown as ArrowDown, ChevronUp as ArrowUp } from 'react-feather';

import { useAccordionContext } from './accordion';
//import Icon from '../Icon';

//import { useStyle } from '../../util/classify';
import defaultClasses from './section.module.scss';
import Icon from 'src/components/Icon';
import { useStyle } from 'packages/framework/util/classify';

const Section = props => {
  const {
    children,
    id,
    title,
    isActionButton = false,
    handleButtonClick = () => {},
    buttonTitle = '',
    ...restProps
  } = props;

  // Remove isOpen from restProps to avoid having it in the root container
  delete restProps.isOpen;

  const { handleSectionToggle, openSectionIds } = useAccordionContext();

  const handleSectionToggleWithId = useCallback(
    () => handleSectionToggle(id),
    [handleSectionToggle, id],
  );

  const isOpen = openSectionIds.has(id);
  const titleIconSrc = isOpen ? ArrowUp : ArrowDown;
  const titleIcon = <Icon src={titleIconSrc} size={24} />;

  const classes = useStyle(defaultClasses, props.classes);
  const contentsContainerClass = isOpen
    ? classes.contents_container
    : classes.contents_container_closed;

  return (
    <div className={classes.root} {...restProps}>
      <button
        className={classes.title_container}
        data-cy="Section-titleContainer"
        onClick={handleSectionToggleWithId}
        type="button"
      >
        <div className="flex flex-row justify-between items-center">
          <span className={classes.title_wrapper}>
            <span className={classes.title}>{title}</span>
          </span>
          <span className={classes.title_wrapper}>
            {isActionButton && (
              <button onClick={handleButtonClick}>
                <span className={classes.title}>{buttonTitle}</span>
              </button>
            )}
            {titleIcon}
          </span>
        </div>
      </button>
      <div className={contentsContainerClass}>{children}</div>
    </div>
  );
};

export default Section;
