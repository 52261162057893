import { gql } from '@apollo/client';

export const CartTriggerFragment = gql`
  fragment CartTriggerFragment on Cart {
    id
    total_quantity
    prices {
      subtotal_excluding_tax {
        currency
        value
      }
      subtotal_including_tax {
        currency
        value
      }
    }
  }
`;
