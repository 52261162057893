import { gql } from '@apollo/client';

export const SIGN_OUT = gql`
    mutation SignOutFromModal {
        revokeCustomerToken {
            result
        }
    }
`;

export const createCartMutation = gql`
  mutation CreateCartAfterCheckout {
    cartId: createEmptyCart
  }
`;

export default {
    signOutMutation: SIGN_OUT,
    createCartMutation
};
