import { Portal } from 'packages/framework/components/Portal';
import { useWindowSize } from 'packages/framework/hooks/useWindowSize';
import './authComponent.scss';

export const AuthWrapper = ({
  showHideClassName,
  showModal,
  close,
  children,
}) => {
  const { isMobile } = useWindowSize();

  return (
    <Portal>
      {showModal ? (
        <div className={`${showHideClassName} modal full-screen`}>
          <div className={'overlay'}></div>
          <div className={`modal-dialog ${showModal ? 'show' : 'n'}`}>
            <div
              className={`authmodal-content ${
                isMobile ? 'mobile-modal' : null
              }`}
            >
              <a
                onClick={close}
                className="close cursor-pointer"
                data-dismiss="modal"
              >
                ×
              </a>
              <div className="modal-body">{children}</div>
            </div>
          </div>
        </div>
      ) : null}
    </Portal>
  );
};
