import { initialState } from './compare.state';
import { compareActionTypes } from './compare.types';

export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case compareActionTypes.INITIAL_STATE:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
};
