import { gql } from '@apollo/client';

export const SUBSCRIBE_TO_NEWSLETTER = gql`
    mutation SubscribeToNewsletter($email: String!) {
        subscribeEmailToNewsletter(email: $email) {
            status
        }
    }
`;

export const updatenewsletter = gql`
mutation ($subscribed:Boolean!){
    updateCustomerV2(
      input: {
        is_subscribed: $subscribed
      }
    ) {
      customer {
        is_subscribed
      }
    }
  }
`;

export const GET_STORE_CONFIG_DATA = gql`
    query GetStoreConfigForNewsletter {
        # eslint-disable-next-line @graphql-eslint/require-id-when-available
        storeConfig {
            store_code
            newsletter_enabled
        }
    }
`;

const GET_CUSTOMER = gql`
  query GetCustomerDetails {
    # eslint-disable-next-line @graphql-eslint/require-id-when-available
    customer {
      email
      firstname
      lastname
      is_subscribed
    }
  }
`;

export default {
    subscribeMutation: SUBSCRIBE_TO_NEWSLETTER,
    getStoreConfigQuery: GET_STORE_CONFIG_DATA,
    updatenewsletter,
    GetCustomerDetails: GET_CUSTOMER
};
