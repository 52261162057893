import { FormattedMessage } from 'react-intl';

import LoadingIndicator from './indicator';

const staticIndicator = (
    <LoadingIndicator global={true}>
        <FormattedMessage
            id={'loadingIndicator.message'}
            defaultMessage={' '}
        />
    </LoadingIndicator>
);

export default staticIndicator;
