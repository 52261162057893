/**
 * Persistence layer with expiration based on localStorage.
 */

const storageMock = {
    length: 0,
    getItem() {},
    setItem() {},
    removeItem() {},
    clear() {}
};

class NamespacedLocalStorage {
    constructor(private localStorage, private key) {}
    _makeKey(key) {
        return `${this.key}__${key}`;
    }
    getItem(name) {
        return this.localStorage.getItem(this._makeKey(name));
    }
    setItem(name, value) {
        return this.localStorage.setItem(this._makeKey(name), value);
    }
    removeItem(name) {
        return this.localStorage.removeItem(this._makeKey(name));
    }
}

export default class BrowserPersistence {
    // static KEY = 'M2_GW_BROWSER_PERSISTENCE';
    static KEY = 'mbx_bp';
    /* istanbul ignore next: test injects localstorage mock */
    storage = null;
    constructor(localStorage = globalThis.localStorage || storageMock) {
        this.storage = new NamespacedLocalStorage(
            localStorage,
            this.constructor['KEY'] || BrowserPersistence.KEY
        );
    }
    getRawItem(name) {
        return this.storage.getItem(name);
    }
    getItem(name) {
        const item = this.storage.getItem(name);
        if (!item) {
            return undefined;
        }
        const { value } = JSON.parse(item);

        return JSON.parse(value);
    }
    setItem(name, value, ttl=null) {
        const timeStored = Date.now();
        this.storage.setItem(
            name,
            JSON.stringify({
                value: JSON.stringify(value),
                timeStored,
                ttl
            })
        );
    }
    removeItem(name) {
        this.storage.removeItem(name);
    }
}
