import SIGNIN_OPERATIONS from '../SignIn/signIn.gql';
import mergeOperations from 'packages/framework/util/shallowMerge';
import { useApolloClient, useMutation } from '@apollo/client';
import { useCallback, useState } from 'react';
import { useUserContext, useUserState } from 'packages/framework/context/user';
import { useCartContext, useCartState } from 'packages/framework/context/cart';
import { useAwaitQuery } from 'packages/framework/hooks/useAwaitQuery';
import { GET_CART_DETAILS_QUERY as getCartDetailsQuery } from 'src/components/AuthComponent/LogInComponent/signIn.gql';
import { retrieveCartId } from 'packages/framework/store/actions/cart';
import { useEventListener } from 'packages/framework/hooks/useEventListener';
import { useAuthModalDrawer } from 'src/components/AuthComponent/useAuthModalDrawer';
import { useDispatchCompare } from 'packages/framework/context/compareContext';
import BrowserPersistence from 'packages/framework/util/simplePersistence';
import { compareActionTypes } from 'packages/framework/context/compare/compare.types';

const storage = new BrowserPersistence();

export const useSignInEvent = (props = {}) => {
  const { handleErrors } = props;
  const operations = mergeOperations(SIGNIN_OPERATIONS, props.operations);

  const {
    createCartMutation,
    getCustomerQuery,
    mergeCartsMutation,
    mergeCompareList,
  } = operations;

  const apolloClient = useApolloClient();
  const [isSigningIn, setIsSigningIn] = useState(false);

  const talonProps = useAuthModalDrawer();
  const { handleClose, isOpen } = talonProps;
  const { cartId } = useCartState();
  const { createCart, removeCart, getCartDetails }: any = useCartContext();

  const { isGettingDetails, isSignedIn } = useUserState();
  const { getUserDetails, setToken }: any = useUserContext();

  const [fetchCartId] = useMutation(createCartMutation);
  const [mergeCarts] = useMutation(mergeCartsMutation);
  const [mergeCompare] = useMutation(mergeCompareList);
  const fetchUserDetails = useAwaitQuery(getCustomerQuery);
  const fetchCartDetails = useAwaitQuery(getCartDetailsQuery);
  const dispatch = useDispatchCompare();

  const socialLoginEventHandler = useCallback(
    async event => {
      setIsSigningIn(true);

      try {
        if (isSignedIn) {
          return null;
        }

        const { token, result, messages, redirect_data } = event.data || {};

        if (result === 1 && token) {
          const sourceCartId = cartId;

          await setToken(token);

          // Clear all cart/customer data from cache and redux.
          await apolloClient.clearCacheData(apolloClient, 'cart');
          await apolloClient.clearCacheData(apolloClient, 'customer');
          await removeCart();
          // Create and get the customer's cart id.
          await createCart({
            fetchCartId,
          });
          const destinationCartId = await retrieveCartId();

          handleClose();

          // Merge the guest cart into the customer cart.
          await mergeCarts({
            variables: {
              destinationCartId,
              sourceCartId,
            },
          });

          // Assign guestUID if present, to user
          const uid = storage.getItem('compare_uid');
          if (uid) {
            await mergeCompare({
              variables: {
                uid,
              },
            }).then(result => {
              if (result.data.assignCompareListToCustomer?.result) {
                storage.setItem(
                  'compare_list_count',
                  result.data.assignCompareListToCustomer?.compare_list
                    ?.item_count,
                );
                storage.setItem(
                  'compare_uid',
                  result.data.assignCompareListToCustomer?.compare_list?.uid,
                );
                dispatch({
                  type: compareActionTypes.INITIAL_STATE,
                  payload: {
                    compare_count:
                      result.data.assignCompareListToCustomer?.compare_list
                        ?.item_count,
                    compare_items:
                      result.data.assignCompareListToCustomer?.compare_list,
                  },
                });
              }
            });
          }

          // Ensure old stores are updated with any new data.
          getUserDetails({ fetchUserDetails });
          getCartDetails({ fetchCartId, fetchCartDetails });
        } else if (result === 0) {
          handleErrors({
            message: messages[0] || null,
            redirectWithError: redirect_data && redirect_data.redirectWithError,
          });
        }

        setIsSigningIn(false);
      } catch (error) {
        if (process.env.NODE_ENV !== 'production') {
          console.error(error);
        }

        setIsSigningIn(false);
      }
    },
    [
      cartId,
      apolloClient,
      removeCart,
      setToken,
      createCart,
      fetchCartId,
      mergeCarts,
      getUserDetails,
      fetchUserDetails,
      getCartDetails,
      fetchCartDetails,
      isSignedIn,
      handleErrors,
    ],
  );

  useEventListener(window, 'message', socialLoginEventHandler);

  return {
    isBusy: isGettingDetails || isSigningIn,
  };
};
