import { createContext, useContext } from 'react';
import { useSignInEvent } from 'src/hooks/SocialAuth/useSignInEvent';
import { useSocialLogin } from 'src/hooks/SocialAuth/useSocialLogin';

const AmSocialLoginContext = createContext<any>({});
const { Provider } = AmSocialLoginContext;

const AmAdvancedReviewsProvider = props => {
  const { children } = props;

  const talonProps = useSocialLogin();
  const { error, needRedirect, redirectUrl, handleErrors } = talonProps;
  const { isBusy } = useSignInEvent({
    needRedirect,
    redirectUrl,
    handleErrors,
  });

  if (error) {
    if (process.env.NODE_ENV !== 'production') {
      console.error(error);
    }

    console.log('Check if Amasty modules has been installed!');
  }

  const contextValue = {
    ...talonProps,
    isBusy,
  };

  return <Provider value={contextValue}>{children}</Provider>;
};

export default AmAdvancedReviewsProvider;

export const useSocialLoginContext = () => useContext(AmSocialLoginContext);
