import { useEffect, useState } from 'react';

const Tabs = ({ tabs, className, actTab }) => {
  const [activeTab, setActiveTab] = useState(actTab || 0);
  useEffect(()=>{
   setActiveTab(actTab || 0);
  },[actTab])
  return (
    <>
      <div className={className}>
        {tabs.map((tab, index) => (
          <a
            key={index}
            className={`mx-auto md:mx-0 text-left ${
              activeTab === index ? `${tab.className} active` : ''
            }`}
            onClick={() => setActiveTab(index)}
            id={tabs.id || null}
          >
            <span>
            {tab.title}
            </span>
          </a>
        ))}
      </div>
      {tabs[activeTab].render()}
    </>
  );
};

export default Tabs;
