import loadGoogleMapsApi from 'load-google-maps-api';
import defaultClasses from './map.module.scss';
import { geocodeByAddress, getLatLng, geocodeByPlaceId } from 'react-places-autocomplete';



export const gtm = gtmData => {
  console.log('__GTM PUSH__', gtmData.event, gtmData);
  try {
    window['dataLayer'].push(gtmData);
  } catch (e) {}
};

export const sendGtmEvent = (event, obj) => {
  return gtm({
    event,
    ...obj,
  });
};

function getCartItems(cart) {
  return cart?.items?.map(
    ({
      product: { name, categories, sku },
      prices: {
        price: { value, currency },
      },
      quantity,
      configurable_options,
    }) => {
      let variant = configurable_options
        ? configurable_options.map(o => o.value_label).join(' - ')
        : undefined;

      let category =
        categories && categories[0] ? categories[0].name : undefined;

      return {
        name: name,
        price: value,
        sku,
        id: sku,
        variant,
        quantity,
        category,
      };
    },
  );
}

export const sendCartLoaded = cart => {
  try {
    let shipping_addresses = cart.shipping_addresses || [];
    let shipping_address = shipping_addresses[0] || {};

    gtm({
      event: 'cartLoaded',
      cart: {
        items: getCartItems(cart),
        address: shipping_address,
      },
    });
  } catch (e) {}
};

export const sendUserLoaded = user => {
  try {
    if (!user) {
      gtm({
        event: 'userLoaded',
        user: {
          loggedInStatus: false,
        },
      });
    } else {
      const { email, firstname, lastname } = user;
      gtm({
        event: 'userLoaded',
        user: {
          loggedInStatus: true,
          email,
          firstname,
          lastname,
        },
      });
    }
  } catch (e) {}
};
export const sendPageView = page => {
  try {
    gtm({
      event: 'pageView',
      page,
    });
  } catch (e) {}
};

export const sendProductDetailImpressions = (product, price, variant) => {

 try {
    //const useCategory;
    const { name, sku, categories} = product;
    const {productprice} = price;

    let category = categories && categories[0] ? categories[0].name : undefined;

    let final_price = product?.price_range?.maximum_price?.final_price.value;
    let discount_amount = product?.price_range?.maximum_price?.discount.amount_off;
    //let discount_amount = product?.short_description?.sku;
    //let sku2 = product?.custom_attributes?.sku;
      let product_color = product?.colour;
    let att =product?.custom_attributes?.[1]?.selected_attribute_options?.attribute_option?.[0]?.label;

  const itemCategorySet = [];
    categories.forEach(({ name }) => {
        itemCategorySet.push(name);
    });

 

    gtm({
      event: 'view_item',
      pagePath: window.location.href,
      pageType: 'product',
      pageTitle: name,
      items: [
        {
          id: sku,
          item_id: sku,
          item_name: name,
          affiliation: "Website",
          //coupon: "SUMMER_FUN",
          discount: discount_amount,
          index: 0,
          item_brand: att,
          item_category: itemCategorySet[0],
          item_category2: itemCategorySet[1],
          item_category3: itemCategorySet[2],
          item_category4: itemCategorySet[3],
          item_category5: itemCategorySet[4],
          item_variant: product_color,
      //    location_id: "ChIJIQBpAG2ahYAR_6128GcTUEo",
      //  location_id:results,
          price: final_price,
          quantity: 1
        },
      ],
      ecommerce: {
        detail: {
          // actionField: { list: 'Apparel Gallery' }, // 'detail' actions have an optional list property.
          products: [
            {
              name,
              id: sku,
              sku,
              price: price ? price.value : undefined,
              variant,
              category,
            },
          ],
        },
      },
    });
  } catch (e) {
    console.log('sendProductDetailImpressions e', e);
  }
};

export const sendProdutListImpression = (category, products) => {
  try {
    gtm({
      event: 'view_item_list',

    items: [
    {
     impressions:products.map((product, index) => ({
          item_name: product.name,
          item_id: product.sku,
          item_brand:category,
          price: product.price_range.minimum_price.final_price.value,
          item_category:category,
         index: +index + 1,
        })),
     
    }
  ],
      ecommerce: {
        currencyCode: 'INR', // Local currency is optional.
        impressions: products.map((product, index) => ({
          name: product.name,
          id: product.sku,
          sku: product.sku,
          price: product.price_range.minimum_price.final_price.value,
          category,
          position: +index + 1,
        })),
      },
    });
  } catch (e) {
    console.log('sendProdutListImpression e', e);
  }
};

// export const cartViewEvent = cart => {
//   try {
//     gtm({
//       event: 'cartView',
//       ecommerce: {
//         checkout: {
//           actionField: { step: 1, option: 'Visa' },
//           products: getCartItems(cart),
//         },
//       },
//     });
//   } catch (e) {}
// };

const findCartItem = (cartItems, cartItemInput) => {
  let { selected_options, sku, id } = cartItemInput;
  return cartItems.find(item => {
    let found = false;

    if (item.product.sku == sku) {
      found = true;
      if (item.configurable_options) {
        for (let {
          configurable_product_option_value_uid,
        } of item.configurable_options) {
          if (
            !selected_options.includes(configurable_product_option_value_uid)
          ) {
            found = false;
          }
        }
      }
    } else if (item.id == id || item.uid == id) {
      found = true;
    }
    return found;
  });
};

let cartEventMap = {
  add: 'add_to_cart',
  remove: 'remove_from_cart',
};

export const sendCartEvents = (
  cartItems,
  cartItemInput,
  action: 'add' | 'remove',
) => {
  try {
    let { sku, quantity } = cartItemInput;

    let cartItem = findCartItem(cartItems, cartItemInput);

    if (!cartItem) return null;

    let {
      product: { name, categories },
      prices: {
        price: { value, currency },
      },
      configurable_options,
    } = cartItem;

    let variant = configurable_options
      ? configurable_options.map(o => o.value_label).join(' - ')
      : undefined;

    let category = categories && categories[0] ? categories[0].name : undefined;

    const cartitemCategorySet = [];
    categories.forEach(({ name }) => {
        cartitemCategorySet.push(name);
    });
 
    const discounts = cartItems[0]?.product?.price_range?.maximum_price?.discount?.amount_off

  const price = cartItems[0]?.prices?.row_total_including_tax?.value
const item_brand = cartItems[0]?.product?.custom_attributes[0]?.selected_attribute_options?.attribute_option?.[0]?.label
  const quantity1 = cartItems[0]?.quantity
const colour = cartItems[0]?.product?.colour

    gtm({
      event: cartEventMap[action],
      ecommerce: {
        currencyCode: currency,
        items: [
          {
            id: sku,
             item_id: sku,
            item_name: name,
           affiliation: "Website",
          // coupon: "SUMMER_FUN",
          // discount: discounts,
          index: 0,
          item_brand:item_brand,
          item_category: cartitemCategorySet[0],
          item_category2: cartitemCategorySet[1],
          item_category3: cartitemCategorySet[2],
          item_category4: cartitemCategorySet[3],
          item_category5: cartitemCategorySet[4],
          item_list_id: cartitemCategorySet[5],
          // item_list_name:"releted product",
          item_variant: colour,
          //location_id: "ChIJIQBpAG2ahYAR_6128GcTUEo",
          price: price,
          quantity: quantity1
          },
        ],
         ecommerce: {
        currencyCode: currency,
        items: [
          {
            id: sku,
          },
        ],

        [action]: {
          products: [
            {
              name,
              price: value,
              id: sku,
              sku,
              variant : colour,
              quantity,
              category,
            },
          ],
        },
      },
    },
    });
  } catch (e) {
    console.log('sendCartEvents e', e);
  }
};

export const sendCheckoutEvent = (event, cart, action) => {
  try {
    const checkout: any = {
      actionField: action, //{ step, option },
      products: getCartItems(cart),
    };
    if (cart) {
      checkout.products = getCartItems(cart);
    }

    // catatgry display
  const catlist = cart?.items[0]?.product?.categories;
 
  const itemCategorySet1 = [];
    catlist.forEach(({ name }) => {
        itemCategorySet1.push(name);
        
    });
const item_brand = cart?.items[0]?.product?.custom_attributes?.[1]?.selected_attribute_options?.attribute_option?.[0]?.label
 
 // End catatgry display


const custome_atttributes = cart?.items[0]?.product?.custom_attributes;


const custCategorySet = [];
    custome_atttributes.forEach(({ selected_attribute_options }) => {
        custCategorySet.push(selected_attribute_options);
        
    });
      gtm(

    {
      event: 'checkout',
      ecommerce: {
        checkout,
    items: [
    {
      item_id:cart?.id ,

      item_name:cart?.items[0]?.product?.name,
      affiliation: "Website",
      coupon:cart?.applied_coupons?.[0]?.code,
      discount:cart?.items[0]?.product?.price_range?.maximum_price?.discount.amount_off,
      index: 0,
      item_brand: item_brand,
      item_category: itemCategorySet1[0],
      item_category2: itemCategorySet1[1],
      item_category3: itemCategorySet1[2],
      item_category4: itemCategorySet1[3],
      item_category5: itemCategorySet1[4],
      // item_list_id: "related_products",
      // item_list_name: "Related Products",
      item_variant: cart?.items[0]?.product?.colour,
      //location_id: "ChIJIQBpAG2ahYAR_6128GcTUEo",
      price: cart?.prices?.grand_total?.value,
      quantity: cart?.total_quantity

    }
  ]

      },
    });
  } catch (e) {
    console.log('sendCheckoutEvent error:: ', e);
  }
};

export const purchaseEvent = (cart, order_number) => {
  try {
    let tax = (cart.prices.applied_taxes || []).map(
      (acc, tax) => acc + tax.amount.value,
      0,
    );

    let revenue = cart.prices.grand_total.value;

    let shippingAddress = (cart.shipping_addresses || [])[0] || {};

    let shipping_amount =
      shippingAddress?.selected_shipping_method?.amount.value;

    gtm({
      event: 'purchase',
      ecommerce: {
        purchase: {
          actionField: {
            id: order_number, // Transaction ID. Required for purchases and refunds.
            affiliation: 'Online Store',
            revenue,
            tax: tax,
            shipping: shipping_amount,
            coupon: cart.applied_coupons,
          },
          products: getCartItems(cart),
        },
      },
    });
  } catch (e) {
    console.log(e);
  }
};
