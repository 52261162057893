import { useWindowSize } from 'packages/framework/hooks/useWindowSize';
// import TopFooter from './TopFooter';
import MiddleFooter from './MiddleFooter';
import BottomFooter from './BottomFooter';



const Footer = () => {
  const { isMobile, isDesktop } = useWindowSize();

  return (
    <>
     {/* <TopFooter />*/}
      <MiddleFooter />
  {/*    <BottomFooter />*/}
    </>
  );
};

export default Footer;
