import { gql } from '@apollo/client';
import { CheckoutPageFragment } from 'packages/framework/hooks/CheckoutPage/checkoutPageFragments.gql';

export const LOGIN_OTP = gql`
  mutation LoginOTP($mobileNumber: String!) {
    loginOTP(mobileNumber: $mobileNumber) {
      status
      message
    }
  }
`;

export const LOGIN_OTP_VERIFY = gql`
  query LoginOTPVerify($mobileNumber: String!, $otp: String!) {
    loginOTPVerify(mobileNumber: $mobileNumber, otp: $otp, websiteId: 1) {
      status
      message
      token
    }
  }
`;

export const GET_CUSTOMER = gql`
  query GetCustomerAfterSignIn {
    # eslint-disable-next-line @graphql-eslint/require-id-when-available
    customer {
      email
      firstname
      lastname
      is_subscribed
    }
  }
`;

export const SIGN_IN = gql`
  mutation SignIn($email: String!, $password: String!) {
    generateCustomerToken(email: $email, password: $password) {
      token
    }
  }
`;

export const CREATE_CART = gql`
  mutation CreateCartAfterSignIn {
    cartId: createEmptyCart
  }
`;

export const MERGE_CARTS = gql`
  mutation MergeCartsAfterSignIn(
    $sourceCartId: String!
    $destinationCartId: String!
  ) {
    mergeCarts(
      source_cart_id: $sourceCartId
      destination_cart_id: $destinationCartId
    ) {
      id
      # eslint-disable-next-line @graphql-eslint/require-id-when-available
      items {
        uid
      }
      ...CheckoutPageFragment
    }
  }
  ${CheckoutPageFragment}
`;

export const MERGE_COMPARE_LIST = gql`
  mutation AssignCompareListToCustomer($uid: ID!) {
    assignCompareListToCustomer(uid: $uid) {
      result
      compare_list {
        uid
        item_count
        attributes {
          code
          label
        }
        items {
          uid
          product {
            id
            uid
            sku
            name
            url_key
            stock_status
            rating_summary
            review_count
            media_gallery_entries {
              uid
              label
              position
              disabled
              file
            }
            media_gallery {
              url
              label
              disabled
              position
            }
            __typename
            image {
              url
            }
            small_image {
              url
            }
            description {
              html
            }
            short_description {
              html
            }
            manufacturer
            price_range {
              maximum_price {
                final_price {
                  currency
                  value
                }
                fixed_product_taxes {
                  amount {
                    currency
                    value
                  }
                  label
                }
                discount {
                  percent_off
                  amount_off
                }
                regular_price {
                  currency
                  value
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default {
  createCartMutation: CREATE_CART,
  getCustomerQuery: GET_CUSTOMER,
  mergeCartsMutation: MERGE_CARTS,
  signInMutation: SIGN_IN,
  loginOtpMutation: LOGIN_OTP,
  loginOtpVerifyMutation: LOGIN_OTP_VERIFY,
  mergeCompareList: MERGE_COMPARE_LIST,
};
